import React, { useEffect, useState } from 'react';
import Sidebar from '../components/layout/Sidebar';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CertificatePreview from '../components/common/CertificatePreview';
import CardHeader from '../components/common/CardHeader';

export const CheckCertificateEmpty = () => {
    let navigate = useNavigate();

    React.useEffect(() => {
        navigate('/certificate/internalcheck/0');
    }, [navigate]);

    return null;
};

const CheckCertificate = ({ mode = "embedded" }) => {
    const [certId, setCertId] = useState('');
    let navigate = useNavigate();
    const [certificate, setCertificate] = useState('');

    let { certificateId } = useParams();

    const handleTypeIn = (e) => {
        let value = e.target.value.replace(/[^0-9A-Za-z]/g, '');
        if (value.length > 12) {
          value = value.slice(0, 12);
        }
        const formattedValue = value.match(/.{1,4}/g)?.join('-') || '';
        setCertId(formattedValue);
      };

    const handleOk = async (e) => {
        e.preventDefault();
        setCertificate(certId);
        if (mode === "external")
            navigate('/certificate/check/' + certId);
        else
            navigate('/certificate/internalcheck/' + certId);
    };

    useEffect(() => {
        console.log(certificateId);
        if (certificateId === "0")
            setCertId('');
        else
            setCertId(certificateId);
    }, [certificateId]);

    if (mode === "external")
        return (
            <main className="bg-secondary z-n1 background-image" style={{ height: '100vh', overflow: 'auto' }}>
                <div className="container d-flex flex-column w47 z-1">
                    <div className='d-flex justify-content-center align-items-center my-3'>
                        <img src="/assets/images/kig-e7Ti-vgY.png" alt="..." />
                        <div className="d-flex justify-content-start text-white">
                            <span>POLISH CHAMBER OF COMMERCE</span>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center w47 card z-1 py-1 px-2 mb-3">
                        <div className="d-flex justify-content-center">
                            <div className='flex-column'>
                                <div>
                                    <h3>CERTIFICATE OF ORIGIN PREVIEW</h3>
                                </div>
                                <div className='d-flex justify-content-center w-100'>
                                    <div>Podgląd świadectwa pochodzenia</div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-column mx-3 mt-2'>
                            <span>Enter the certificate data to display its preview.</span>
                            <span className='text-black text-opacity-50 mb-1'>Wprowadź dane świadectwa, aby wyświetlić jego podgląd.</span>

                            <div className="flex-column">
                                <form onSubmit={handleOk}>
                                    <div className="d-flex flex-wrap">
                                        <label className="form-label">Certificate Identifier <span className='text-black text-opacity-50'> - Identyfikator świadectwa</span></label>
                                        <input type="text" className="form-control fs-4 fw-bold mb-2 h2 rounded-3" maxLength="14" value={certId} onChange={(e) => handleTypeIn(e)} pattern='^[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}$' placeholder="ex. 96B0-2B56-C27F" required={true} />
                                        <button type="submit" className="btn btn-primary w7 h2 rounded-2 ms-auto" disabled={certId.replace(/-/g, '').length !== 12}>
                                            Ok
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {(certificateId !== '0') && (
                            <div className='d-flex flex-column mx-3 mt-2'>
                                <CertificatePreview className="mw-100" applicationId={certificateId} mode="external" />
                                <div className='flex-column mt-2 pt-1 border-top'>
                                    <div className='tooltip fw-bold text-black fs-4 mb-1'>
                                        View the certificate on the International Chamber of Commerce website
                                        <span className="tooltiptext fw-normal fs-5">Podgląd świadectwa na stronie International Chamber of Commerce</span>
                                    </div>

                                    <div className='tooltip text-black'>
                                        You can also check this certificate in the global certificate database maintained by the International Chamber of Commerce. To do this, click the button below (you will be redirected to the ICC website).
                                        <span className="tooltiptext">Powyższe świadectwo możesz również sprawdzić w ogólnoświatowej bazie świadectw prowadzonej przez International Chamber of Commerce. W tym celu kliknij poniższy przycisk (zostaniesz przekierowany do strony ICC).</span>
                                    </div>

                                    <div className='d-flex flex-row mt-1'>
                                    <button className="btn tooltip btn-primary w11 h2 rounded-2 ms-auto text-white" onClick={() => alert("Redirecting to ICC website.")}>
                                        Check on ICC website
                                        <span className="tooltiptext">Przejście na stronę ICC</span>
                                    </button>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </main>
        );

    if (mode === "embedded")
        return (
            <main className="d-flex flex-nowrap" style={{ height: '100vh' }}>
                <div className="d-flex flex-column">
                    <Sidebar />
                </div>
                <div className="content">
                    <CardHeader text="Podgląd świadectwa pochodzenia" onlyText="true" />

                    <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                        <div className='d-flex flex-column'>
                            {(certificateId !== '0') ? (
                                <div>
                                    <span>Identyfikator świadectwa: <strong>{certificateId}</strong></span>
                                    <div style={{ maxHeight: '80%', overflowY: 'auto' }}>
                                        <CertificatePreview className="mw-100" applicationId={certificateId} />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <span>Wprowadź dane świadectwa, aby wyświetlić jego podgląd.</span>
                                    <div className="mb-1">{certificate}</div>
                                    <div className="flex-column">
                                        <form onSubmit={handleOk}>
                                            <div className="d-flex flex-row flex-wrap">
                                                <label className="form-label">Identyfikator świadectwa</label>
                                                <input type="text" className="form-control mb-4 h2 rounded-3" maxLength="14" value={certId} onChange={(e) => handleTypeIn(e)} pattern='^[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}$' placeholder="np. 96B0-2B56-C27F" required={true} />
                                                <button type="submit" className="btn btn-primary w7 h2 rounded-2 ms-auto" disabled={certId.replace(/-/g, '').length !== 12}>
                                                    Ok
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        );
};

export default CheckCertificate;
