import React from 'react';
import { useParams } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import './assets/styles/sidebars.css'
import Consents from './pages/Consents';
import AddConsent from './pages/AddConsent';
import CheckCertificate, { CheckCertificateEmpty } from './pages/CheckCertificate';
import Businesses from './pages/Businesses';
import AddBusiness from './pages/AddBusiness';
import Applications from './pages/Applications';
import ApplicationsToPay from './pages/ApplicationsToPay';
import ProcessApplication from './pages/ProcessApplication';
import UserManagement from './pages/UserManagement';
import UserEdit from './pages/UserEdit';
import ProfileEdit from './pages/ProfileEdit';
import PaymentFinish from './pages/PaymentFinish';

function RedirectToDefaultApplicationStep() {
  return <Navigate to="/application/edit/1/00000000-0000-0000-0000-000000000000" />;
}

function RedirectToDefaultUserEdit() {
  return <Navigate to="/user/edit/00000000-0000-0000-0000-000000000000" />;
}

function RedirectToMyApplications() {
  return <Navigate to="/application/list" />;
}

function RedirectToCertificateCheck({empty = false}) {
  const { certificateId } = useParams();
  if (empty)
    return <Navigate to="/certificate/check/0" />;
  return <Navigate to={"/certificate/check/" + certificateId} />;
}

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<RedirectToMyApplications />} />
          <Route path="/login" element={<LoginPage mode='login' />} />
          <Route path="/register" element={<LoginPage mode='register' />} />
          <Route path="/register/success" element={<LoginPage mode='register-success' />} />
          <Route path="/register/failure" element={<LoginPage mode='register-failure' />} />
          <Route path="/register/activate/:userId" element={<LoginPage mode='activate' />} />
          <Route path="/application/add" element={<RedirectToDefaultApplicationStep />} />
          <Route path="/application/edit/:step/:id" element={<ProcessApplication />} />
          <Route path="/application/list" element={<Applications />} />
          <Route path="/application/toPay" element={<Applications paymentMode={true} />} />
          <Route path="/application/shipping" element={<Applications shippingMode={true} />} />
          <Route path="/application/paymentCompleted/:applicationId" element={<PaymentFinish />} />
          <Route path="/account/consents/add/:consentType" element={<AddConsent />} />
          <Route path="/business/add" element={<AddBusiness />} />
          <Route path="/business/list" element={<Businesses />} />
          <Route path="/user/add" element={<RedirectToDefaultUserEdit />} />
          <Route path="/user/edit/:id" element={<UserEdit />} />
          <Route path="/user/list" element={<UserManagement />} />
          <Route path="/user/profile" element={<ProfileEdit />} />
          <Route path="/preview" element={<RedirectToCertificateCheck empty={true} />} />
          <Route path="/preview/:certificateId" element={<RedirectToCertificateCheck />} />
          <Route path="/certificate/check/:certificateId" element={<CheckCertificate mode="external" />} />
          <Route path="/certificate/internalcheck" element={<CheckCertificateEmpty />} />
          <Route path="/certificate/internalcheck/:certificateId" element={<CheckCertificate mode="embedded" />} />
        </Routes>
    </Router>
  );
}

export default App;