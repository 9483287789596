import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ApiHost } from '../../utils/ApiConfig';
import ValidationInfo from './ValidationInfo';

const BusinessAdd = () => {
    const { accessToken } = useAuth();
    const { userId } = useAuth();
    let navigate = useNavigate();

    const [showAlert, setShowAlert] = useState(false);

    const [formValid, setFormValid] = useState(false);
    const [nip, setNip] = useState('');
    const [nipError, setNipError] = useState('');
    const [nipValid, setNipValid] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [businessNameError, setBusinessNameError] = useState('');
    const [street, setStreet] = useState('');
    const [streetError, setStreetError] = useState('');
    const [building, setBuilding] = useState('');
    const [buildingError, setBuildingError] = useState('');
    const [number, setNumber] = useState('');
    const [numberError, setNumberError] = useState('');
    const [postCode, setPostCode] = useState('');
    const [postCodeError, setPostCodeError] = useState('');
    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState('');

    const handleAccept = async (e) => {
        e.preventDefault();
        try {
            const data = {
                "BusinessName": businessName,
                "Street": street,
                "Building": building,
                "Number": number,
                "PostCode": postCode,
                "City": city
            }

            cleanValidators();
            console.log("Dodawanie powiązania użytkownika z firmą " + businessName);
            const response = await axios.post(ApiHost + `/business/add`, {
                nip: nip,
                user_id: userId,
                data: data
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            if (response.status === 200) {
                console.log("Dodano powiązanie użytkownika z firmą [" + userId + "]");
                navigate('/business/list', { replace: true });
            }

        } catch (error) {
            console.error("Błąd podczas dodawania firmy:", error);
        }
    };

    // eslint-disable-next-line no-extend-native
    String.prototype.isLetterOrDigit = function () {
        return (/^([a-zA-Z0-9]{1})$/gi).test(this);
    };

    function fillResult(response) {
        const data = response.data;

        setBusinessName(data.Name);
        setStreet(data.Street);
        setBuilding(data.Building);
        if (data.Number)
            setNumber(data.Number);
        setPostCode(data.PostCode);
        setCity(data.City);
    }

    function handleGusError() {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }

    const gusSearch = async () => {
        try {
            console.log("Pobieranie danych z GUS [" + nip + "]")
            await axios.post(ApiHost + '/gus/search', {
                nip: nip
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    fillResult(response);
                })
                .then(cleanValidators())
                .catch(error => handleGusError());
        } catch (error) {
            console.warn('Błąd pobierania danych z GUS: ', error);
        }
    };

    const handleNipKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            gusSearch();
        }
    };

    const handleNipInput = (e) => {
        const value = e.target.value;

        // Umożliwia wpisanie tylko cyfr
        if (/^[0-9]*$/.test(value)) {
            setNip(value);
            setShowAlert(false);

            if (validateNIP(value))
                setNipValid(true);
            else
                setNipValid(false);
        }
    };

    const handleBusinessNameInput = (e) => {
        const value = e.target.value;

        if (/^[a-zA-Z0-9.,"'-/ ]*$/.test(value)) {
            setBusinessName(value);
        }
    };

    const handleStreetInput = (e) => {
        const value = e.target.value;

        if (/^[a-zA-Z0-9.,"'-/ ]*$/.test(value)) {
            setStreet(value);
        }
    };

    const handleBuildingInput = (e) => {
        const value = e.target.value;

        if (/^\d+[a-zA-Z\d,\/ -]*$/.test(value)) {
            setBuilding(value);
        }
    };

    const handleNumberInput = (e) => {
        const value = e.target.value;

        if (value === '' || /^\d*[0-9a-zA-Z]\w*$/.test(value)) {
            setNumber(value);
        }
    };

    const handlePostCodeInput = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, ''); // Usunięcie wszystkich znaków, które nie są cyframi
        if (value.length > 2) {
            // Wstawienie kreski po dwóch pierwszych cyfrach
            value = value.slice(0, 2) + '-' + value.slice(2);
        }
        setPostCode(value);
    };

    const handleCityInput = (e) => {
        const value = e.target.value;

        if (/^[a-zA-Z0-9.-/ ]*$/.test(value)) {
            setCity(value);
        }
    };

    const handleCancel = async (e) => {
        e.preventDefault();
        navigate('/business/list', { replace: true });
    };

    function validateNIP(nip) {
        nip = nip.replace(/[\s-]/g, ''); // Usuwanie spacji i myślników

        if (nip.length !== 10 || !/^\d+$/.test(nip)) {
            return false; // Sprawdzenie, czy NIP ma 10 cyfr
        }

        const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]; // Wagi dla poszczególnych cyfr NIP
        const controlSum = nip
            .substring(0, 9)
            .split('')
            .reduce((sum, digit, index) => sum + parseInt(digit) * weights[index], 0);

        const controlNumber = controlSum % 11;
        const controlDigit = parseInt(nip[9]);

        return controlNumber === controlDigit;
    }

    function validateBusinessName() {
        if (!businessName)
            return false;
        return true;
    }

    function validateStreet() {
        if (!street)
            return false;
        return true;
    }

    function validateBuilding() {
        if (!building)
            return false;
        if (building.length > 10)
            return false;
        if (!/^\d+[a-zA-Z\d,\/ -]*$/.test(building))
            return false; //"Nieprawidłowy format numeru budynku. Dozwolone są cyfry, litery oraz znaki ',', '-', '/', ' '.";
        return true;
    }

    function validateNumber() {
        if (!number)
            return true;
        if (number.length > 10)
            return false;
        if (!/^\d*[0-9a-zA-Z]\w*$/.test(number))
            return false; //"Nieprawidłowy format numeru lokalu. Dozwolone są cyfry, litery oraz znaki ',', '-', '/', ' '.";    
        return true;
    }

    function validatePostCode() {
        if (!/^\d{2}-\d{3}$/.test(postCode))
            return false;
        return true;
    }

    function validateCity() {
        if (!city)
            return false;
        return true;
    }

    function cleanValidators() {
        setNipError("");
        setBusinessNameError("");
        setStreetError("");
        setBuildingError("");
        setNumberError("");
        setPostCodeError("");
        setCityError("");
        setFormValid(true);
    }

    const handleValidate = () => {
        cleanValidators();

        if (!validateNIP(nip)) {
            setNipError("Podaj poprawny numer NIP.");
            setFormValid(false);
        }
        if (!validateBusinessName()) {
            setBusinessNameError("Podaj poprawną nazwę firmy.");
            setFormValid(false);
        }
        if (!validateStreet()) {
            setStreetError("Podaj poprawną ulicę.");
            setFormValid(false);
        }
        if (!validateBuilding()) {
            setBuildingError("Podaj poprawny numer budynku.");
            setFormValid(false);
        }
        if (!validateNumber()) {
            setNumberError("Podaj poprawny lokal.");
            setFormValid(false);
        }
        if (!validatePostCode()) {
            setPostCodeError("Podaj poprawny kod pocztowy.");
            setFormValid(false);
        }
        if (!validateCity()) {
            setCityError("Podaj poprawną miejscowość.");
            setFormValid(false);
        }
    };

    return (
        <div>
            <div className='card container-fluid shadow ms-4 px-4 py-3 w80'>
                <div className='d-flex flex-column w-100'>
                    <form onSubmit={handleAccept}>
                        <div className="d-flex flex-column flex-wrap">
                            <div className='mb-1'>
                                <div className='d-flex flex-row'>
                                    <label className="form-label">NIP *</label>
                                    <ValidationInfo visible={!nipValid} title="Podaj poprawny numer NIP" wide={true} />
                                </div>
                                <div className='d-flex flex-row'>
                                    <input 
                                        type="text" 
                                        className="form-control h2" 
                                        maxLength="10" 
                                        value={nip} 
                                        onChange={handleNipInput} 
                                        onKeyDown={handleNipKeyPress} 
                                        pattern='^[0-9]{10}$' 
                                        placeholder="Numer NIP, np. 5422838585" 
                                        onBlur={handleValidate} 
                                        required={true} 
                                        invalid={nipValid ? "false" : "true"}
                                    />
                                    <div>
                                        <button 
                                            className="btn btn-outline-primary ms-1 w9 h2" 
                                            type="button" 
                                            disabled={!nipValid} 
                                            onClick={gusSearch}
                                        >
                                            Pobierz dane z GUS
                                        </button>
                                    </div>
                                </div>
                                {
                                    true && (
                                        <div 
                                            className={`alert alert-warning alert-dismissible d-flex align-items-center fixed-top w-25 mt-5 ms-auto fade ${showAlert ? "elementFromRight" : ""}`} 
                                            role="alert"
                                        >
                                            <div>
                                                <strong>Brak danych w GUS</strong><br />Sprawdź poprawność danych.
                                            </div>
                                            <button 
                                                type="button" 
                                                className="btn-close" 
                                                onClick={() => setShowAlert(false)} 
                                                aria-label="Close"
                                            ></button>
                                        </div>
                                    )
                                }
                            </div>

                            <div className='mb-1'>
                                <div className='d-flex flex-row'>
                                    <label className="form-label">Nazwa firmy *</label>
                                    <ValidationInfo visible={businessNameError !== ""} title="Podaj poprawną nazwę firmy" wide={true} />
                                </div>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    maxLength="50" 
                                    value={businessName} 
                                    onChange={handleBusinessNameInput} 
                                    placeholder="Nazwa firmy, np. Gamma-Alfa sp. z o.o." 
                                    onBlur={handleValidate} 
                                    required={true} 
                                    invalid={businessNameError !== "" ? "true" : "false"}
                                />
                            </div>
                            <div className='mb-1 d-flex flex-row'>
                                <div className='w-50'>
                                    <div className='d-flex flex-row'>
                                        <label className="form-label">Ulica *</label>
                                        <ValidationInfo visible={streetError !== ""} title="Podaj poprawną ulicę" />
                                    </div>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        maxLength="50" 
                                        value={street} 
                                        onChange={handleStreetInput} 
                                        placeholder="Ulica, np. ul. Białostocka" 
                                        onBlur={handleValidate} 
                                        required={true} 
                                        invalid={streetError !== "" ? "true" : "false"}
                                        />
                                </div>

                                <div className='w-25 ms-1'>
                                    <div className='d-flex flex-row'>
                                        <label className="form-label">Budynek *</label>
                                        <ValidationInfo visible={buildingError !== ""} title="Podaj poprawny numer budynku" wide={true} />
                                    </div>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        maxLength="10" 
                                        value={building} 
                                        onChange={handleBuildingInput} 
                                        placeholder="Numer budynku" 
                                        onBlur={handleValidate} 
                                        required={true} 
                                        invalid={buildingError !== "" ? "true" : "false"}
                                    />
                                </div>

                                <div className='w-25 ms-1'>
                                    <div className='d-flex flex-row'>
                                        <label className="form-label">Lokal</label>
                                        <ValidationInfo visible={numberError !== ""} title="Podaj poprawny numer lokalu" wide={true} />
                                    </div>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        maxLength="10" 
                                        value={number} 
                                        onChange={handleNumberInput} 
                                        placeholder="Numer lokalu" 
                                        onBlur={handleValidate} 
                                        required={false} 
                                        invalid={numberError !== "" ? "true" : "false"}
                                    />
                                </div>
                            </div>

                            <div className='mb-1'>
                                <div className='d-flex flex-row'>
                                    <label className="form-label">Kod pocztowy *</label>
                                    <ValidationInfo visible={postCodeError !== ""} title="Podaj poprawny kod pocztowy" wide={true} />
                                </div>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    maxLength="6" 
                                    value={postCode} 
                                    onChange={handlePostCodeInput} 
                                    placeholder="Kod pocztowy, np. 15-048" 
                                    onBlur={handleValidate} 
                                    required={true} 
                                    invalid={postCodeError !== "" ? "true" : "false"}
                                />
                            </div>

                            <div className='mb-2'>
                                <div className='d-flex flex-row'>
                                    <label className="form-label">Miejscowość *</label>
                                    <ValidationInfo visible={cityError !== ""} title="Podaj poprawną miejscowość" wide={true} />
                                </div>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    maxLength="50" 
                                    value={city} 
                                    onChange={handleCityInput} 
                                    placeholder="Miejscowość, np. Białystok" 
                                    onBlur={handleValidate} 
                                    required={true} 
                                    invalid={cityError !== "" ? "true" : "false"}
                                />
                            </div>

                            <div className="d-flex flex-row w-100">
                                <button 
                                    className="btn btn-outline-primary me-auto w9 h2" 
                                    onClick={handleCancel}
                                >
                                    Anuluj
                                </button>
                                <button 
                                    type="submit" 
                                    className="btn btn-primary ms-auto w9 h2" 
                                    disabled={!formValid}
                                >
                                    Dodaj
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BusinessAdd;
