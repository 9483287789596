import React, { useState, useEffect } from 'react';
import CountrySelector from './CountrySelector';
import ValidationInfo from './ValidationInfo';
import { validateLettersDigits, validateDigits, validateCountryCode } from '../../utils/Validators';
import PreviousValue from './PreviousValue';

const ProductComponent = ({ name, index, data, previousData = {}, showPreviousData = false, onDataChange, onRemove, handleValidate }) => {
    const [componentName, setComponentName] = useState(data.componentName);
    const [originCountry, setOriginCountry] = useState(data.originCountry);
    const [tariffCode, setTariffCode] = useState(data.tariffCode);
    const [validateTrigger, setValidateTrigger] = useState(0);
    //const [validators, setValidators] = useState({ componentName: "", originCountry: "", tariffCode: "" });

    // Funkcje zwrotne do aktualizacji stanu komponentów
    const handleComponentNameChange = (e) => {
        setComponentName(e.target.value);
        onDataChange({ componentName: e.target.value, originCountry, tariffCode }, index);
        //setValidators({ ...validators, componentName: (validateLettersDigits(e.target.value, 100, true) ? "false" : "true") });
    };

    const handleOriginCountryChange = (e) => {
        setOriginCountry(e.target.value);
        onDataChange({ componentName, originCountry: e.target.value, tariffCode }, index);
        //setValidators({ ...validators, originCountry: (validateCountryCode(e.target.value, true) ? "false" : "true") });
    };

    const handleTariffCodeChange = (e) => {
        if (/^[0-9]*$/.test(e.target.value)) {
            setTariffCode(e.target.value);
            onDataChange({ componentName, originCountry, tariffCode: e.target.value }, index);
            //setValidators({ ...validators, tariffCode: (validateDigits(e.target.value, 10, true) ? "false" : "true") });
        }
    };

    // useEffect(() => {
    //     let count = 0;
    //     for (const key in validators) {
    //         if (validators.hasOwnProperty(key) && ((validators[key] === "true") || validators[key] === true)) {
    //             count++;
    //         }
    //     }
    //     //handleValidate(name, (count === 0) ? "false" : "true");
    // }, [validators]);

    return (
        <div className='d-flex flex-row mb-1'>
            <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                    <label className="form-label">
                        Materiał / surowiec / komponent
                    </label>
                    {/* <ValidationInfo
                        title="Wypełnienie pola jest obowiązkowe. Długość nie może przekraczać 100 znaków."
                        visible={validators.componentName === "true" ? "true" : "false"}
                    /> */}
                </div>
                <input
                    type="text"
                    className="form-control my-0 w19 h2 rounded-3"
                    maxLength="100"
                    name={name + "_componentName"}
                    key={name + "_componentName"}
                    value={componentName}
                    onChange={handleComponentNameChange}
                    placeholder="Materiał / surowiec / komponent"
                    required={true}
                    //invalid={validators.componentName === "true" ? "true" : "false"}
                    onBlur={handleComponentNameChange}
                />
            </div>

            <div className='ms-1 w11'>
                <PreviousValue show={showPreviousData && (previousData.originCountry !== originCountry)} previousValue={previousData.originCountry}>
                    <CountrySelector
                        data={originCountry}
                        label="Kraj pochodzenia"
                        required="false"
                        name={name + "_originCountry"}
                        key={name + "_originCountry"}
                        value={originCountry}
                        onDataChange={handleOriginCountryChange}
                        validateTrigger={validateTrigger}
                        allowUnknown="true"
                    />
                </PreviousValue>
            </div>

            <div className='d-flex flex-column ms-1 w11'>
                <div className='d-flex align-items-center'>
                    <label className="form-label">
                        Kod taryfy celnej
                    </label>
                    {/* <ValidationInfo
                        title="Wypełnienie pola jest obowiązkowe. Długość nie może przekraczać 10 znaków (cyfr)."
                        visible={validators.tariffCode === "true" ? "true" : "false"}
                    /> */}
                </div>
                <PreviousValue show={showPreviousData && (previousData.tariffCode !== tariffCode)} previousValue={previousData.tariffCode}>
                    <input
                        type="text"
                        className="form-control my-0 w11 h2 rounded-3"
                        maxLength="10"
                        name={name + "_tariffCode"}
                        key={name + "_tariffCode"}
                        value={tariffCode}
                        onChange={handleTariffCodeChange}
                        placeholder="Kod taryfy celnej"
                        required={true}
                        // invalid={validators.tariffCode === "true" ? "true" : "false"}
                        onBlur={handleTariffCodeChange}
                    />
                </PreviousValue>
            </div>

            <div className='d-flex flex-column ms-2'>
                <button
                    className='btn btn-outline-primary mt-auto mb-0 p-0 w2 h2 fs-4'
                    onClick={onRemove}
                    title="Usuń materiał/surowiec/komponent"
                >
                    <span className='bi-trash'></span>
                </button>
            </div>
        </div>
    );
}

export default ProductComponent;