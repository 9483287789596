import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import DataTableApp from './DataTableApp';
import { useNavigate } from 'react-router-dom';
import { ApiHost } from '../../utils/ApiConfig';
import { assignOldestApplication, copyApplication } from '../../services/Application';
import LoadingSpinner from './LoadingSpinner';
import ValidationInfo from './ValidationInfo';
import './ApplicationList.css';
import { validateBusinessName } from '../../utils/Validators';


const ApplicationList = ({ mode = "user", paymentMode = false, shippingMode = false }) => {
    const { accessToken, userId, chamberId, chamberName } = useAuth();
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [needsRefresh, setNeedsRefresh] = useState(0);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newDraftName, setNewDraftName] = useState('');
    const [draftNameValid, setDraftNameValid] = useState(false);
    const [selectedApplicationId, setSelectedApplicationId] = useState(null);

    let navigate = useNavigate();

    var data = [];
    const itemsPerPage = 10;

    const getApplication = async () => {
        setLoading(true);

        await assignOldestApplication(userId, userId, accessToken)
            .then(result => {
                if (result !== "false") {
                    console.log("Przypisano najstarszy wniosek: ", result);
                    //navigate(`/application/${result.id}`, { replace: true });
                    setNeedsRefresh(needsRefresh + 1);
                } else {
                    console.error("Nie udało się przypisać najstarszego wniosku.");
                }
            })
            .catch(error => console.error('Błąd podczas przypisywania najstarszego wniosku: ', error));

        setLoading(false);
    };

    const handleCopyApplication = (applicationId, applicationName) => {
        setSelectedApplicationId(applicationId);
        if (applicationName) {
            setNewDraftName(applicationName + ' (kopia)');
            setDraftNameValid(validateBusinessName(applicationName));
        }
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNewDraftName('');
    };

    const handleDraftNameChange = (e) => {
        const value = e.target.value;

        if (/^[a-zA-Z0-9.,"'-/ąćęłńóśźżĄĆĘŁŃÓŚŹŻ ]*$/.test(value)) {
            setNewDraftName(value);
            setDraftNameValid(validateBusinessName(value));
        }
    };

    const handleSaveCopy = async () => {
        try {
            let newId = null;
            newId = await copyApplication(selectedApplicationId, userId, newDraftName, accessToken);
            console.log('newId:', newId, 'selectedApplicationId:', selectedApplicationId, 'newDraftName:', newDraftName);
            if (newId) {
                console.log('Kopia wniosku została utworzona:', newId);
                setNeedsRefresh(needsRefresh + 1);
                handleCloseModal();
            } else {
                console.error('Błąd podczas tworzenia kopii wniosku.');
            }
        } catch (error) {
            console.error('Błąd podczas tworzenia kopii wniosku:', error);
        }
    };

    useEffect(() => {
        const getApplications = async () => {
            let apiMethod = '/application/list';
            switch (mode) {
                case "user":
                    apiMethod = '/application/list';
                    setHiddenColumns(['Przypisany do', 'Izba']);
                    break;
                case "operator":
                    apiMethod = '/application/assigned';
                    setHiddenColumns(['Nazwa', 'Przypisany do', 'Data utworzenia', 'Izba']);
                    break;
                case "coordinator":
                case "shipping":
                    apiMethod = '/application/assigned_to_chamber';
                    setHiddenColumns(['Nazwa', 'Data utworzenia', 'Izba']);
                    break;
                default:
                    apiMethod = '/application/list';
                    break;
            }

            try {
                console.log("Pobieranie listy wniosków dla użytkownika: " + userId)
                const response = await axios.post(ApiHost + apiMethod, {
                    user_id: userId,
                    chamber_id: chamberId
                }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                    .then(response => {
                        const receivedApplications = response.data;
                        data = [];
                        receivedApplications.forEach(application => {
                            data.push({
                                "Numer": (application.Number ? ("000000" + application.Number).slice(-7) : "#"),
                                "Nazwa": application.Name,
                                "Eksporter": (application.ExporterName ? application.ExporterName : "Brak danych"),
                                "NIP": (application.ExporterNIP ? application.ExporterNIP : "Brak NIP"),
                                "Status": application.StatusName,
                                "Przypisany do": (application.OperatorName ? application.OperatorName : "Brak przypisania"),
                                "Izba": (application.ChamberName ? application.ChamberName : "Brak przypisania"),
                                "Data utworzenia": application.CreateDate,
                                "Data złożenia": application.SentDate,
                                "Data aktualizacji": application.LastUpdate,
                                "Action": application.Id
                            });
                        });
                        setApplications(data);
                    })
                    .catch(error => console.error('Błąd pobierania listy wniosków: ', error));
            } catch (error) {
                console.error('Błąd pobierania listy wniosków: ', error);
            }
        };

        getApplications();
    }, [userId, accessToken, needsRefresh]);

    return (
        <div className='card container-fluid shadow ms-4 px-4 py-3 w80'>
            <div className='d-flex flex-column w72'>
                {mode === "user" && (
                    <div className='d-flex flex-row mb-2'>
                        <div>
                            W tym widoku możesz śledzić postęp obsługi swoich wniosków.
                        </div>
                        <div className='ms-auto'>
                            <button className="btn btn-primary w11 h2" onClick={() => { navigate('/application/add', { replace: true }); }}>Utwórz wniosek</button>
                        </div>
                    </div>
                )}

                {mode === "operator" && (
                    <div className='d-flex flex-row mb-2'>
                        <div>
                            W tym widoku możesz śledzić postęp obsługi wniosków, które zostały Ci przypisane.
                        </div>
                        <div className='ms-auto'>
                            {loading ? (
                                <button className="btn btn-primary w11 h2" disabled><LoadingSpinner text='Pobieranie wniosku' /></button>
                            ) : (
                                <button className="btn btn-primary w11 h2" onClick={() => { getApplication(); }}>Pobierz wniosek</button>
                            )}
                        </div>
                    </div>
                )}

                {mode === "coordinator" && (
                    <div className='d-flex flex-row mb-1'>
                        <div>
                            W tym widoku możesz zarządzać wnioskami przypisanymi do izby: <strong>{chamberName}</strong>.
                        </div>
                    </div>
                )}

                {mode === "shipping" && (
                    <div className='d-flex flex-row mb-1'>
                        <div>
                            W tym widoku możesz zarządzać wysyłką wniosków przypisanych do izby: <strong>{chamberName}</strong>.
                        </div>
                    </div>
                )}
                <DataTableApp
                    data={applications}
                    itemsPerPage={itemsPerPage}
                    columnHeaders={['Numer', 'Nazwa', 'Eksporter', 'NIP', 'Status', 'Przypisany do', 'Izba', 'Data utworzenia', 'Data złożenia', 'Data aktualizacji', 'Action']}
                    hiddenColumns={hiddenColumns}
                    initialFilterStatuses={paymentMode ? ['do opłacenia', 'do opłaty i podpisu', 'opłata za usługę'] : shippingMode ? ['do wydruku', 'do wysyłki', 'wysłano'] : []}
                    colorMode={mode === "user" ? 'Client' : 'Operator'}
                    copyHandler={handleCopyApplication}
                    lockStatuses={paymentMode || shippingMode}
                />
                {showModal && (
                    <div className="modal-overlay">
                        <div className="modal-content card d-flex flex-column w35 p-2">
                            <div className="modal-header mb-1">
                                <h4>Kopia wniosku</h4>
                            </div>
                            <div className="flex-column mb-2">
                            <div className='d-flex flex-row'>
                                <label htmlFor="draftName" className="form-label">Nazwa robocza kopii</label>
                                <ValidationInfo visible={!draftNameValid} title="Wypełnienie pola jest obowiązkowe. Maksymalna ilość znaków - 160" wide={true} />
                            </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={newDraftName}
                                    onChange={handleDraftNameChange}
                                    invalid={draftNameValid ? "false" : "true"}
                                    placeholder="Wprowadź nazwę roboczą nowego wniosku"
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary w7 h2" onClick={handleCloseModal}>
                                    Anuluj
                                </button>
                                {draftNameValid ? (
                                    <button type="button" className="btn btn-primary w7 ms-1 h2" onClick={handleSaveCopy}>
                                        Zapisz
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-primary w7 ms-1 h2" disabled>
                                        Zapisz
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ApplicationList;
