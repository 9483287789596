import axios from 'axios';
import { ApiHost } from '../utils/ApiConfig';

const debug = true;

const Payment = {
    order: async (applicationId, userId, amount, accessToken) => {
        try {
            if (debug) console.log("Tworzenie zamówienia dla wniosku [" + applicationId + "]");
            const response = await axios.post(ApiHost + `/payu/order`, {
                order_id: applicationId,
                user_id: userId,
                amount: amount
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
    
            if (response.status === 200) {
                let result = response.data;
                if (debug) console.log("createOrder - content: ", JSON.stringify(result));
                console.log("createOrder - URI: ", result.redirect_uri);
                return result.redirect_uri;
            }
    
        } catch (error) {
            console.error("Błąd podczas tworzenia zamówienia dla wniosku: ", error);
        }
    },

    verify: async (applicationId, accessToken) => {
        try {
            if (debug) console.log("Weryfikacja płatności dla wniosku [" + applicationId + "]");
            const response = await axios.get(ApiHost + `/payu/verify/` + applicationId, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
    
            if (response.status === 200) {
                let result = response.data;
                if (debug) console.log("verifyPayment - content: ", JSON.stringify(result));
                return result;
            }
    
        } catch (error) {
            console.error("Błąd podczas weryfikacji płatności dla wniosku: ", error);
        }
    }

}

export default Payment;