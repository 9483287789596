import React from 'react';
import Step from './Step';
import { useLocation, useNavigate } from 'react-router-dom';

let stepTitles = [
    'WNIOSKODAWCA',
    'EKSPORTOWANY PRODUKT / TOWAR',
    'PROCES PRODUKCJI / ODBIORCA TOWARU',
    'ZAŁĄCZNIKI',
    'PODSUMOWANIE / OŚWIADCZENIA'
];

let stepWarnings = [0, 0, 0, 0, 0];

let idToAppend = "";


function Stepper({ activeStep = 1, titles = null, warnings = [0, 0, 0, 0, 0], messages = [0, 0, 0, 0, 0], onclick = null, id = null, applicationMode = true }) {

    const navigate = useNavigate();
    const location = useLocation()
    const ownAddress = '/' + location.pathname.split('/')[1] + '/' + location.pathname.split('/')[2];
    if (id) idToAppend = '/' + id;
    let stepActions = [];
    if (applicationMode && (id !== "00000000-0000-0000-0000-000000000000"))
        stepActions = [
            () => navigate(ownAddress + '/1' + idToAppend, { replace: true }),
            () => navigate(ownAddress + '/2' + idToAppend, { replace: true }),
            () => navigate(ownAddress + '/3' + idToAppend, { replace: true }),
            () => navigate(ownAddress + '/4' + idToAppend, { replace: true }),
            () => navigate(ownAddress + '/5' + idToAppend, { replace: true })
        ]

    if (titles) stepTitles = titles;
    //if (warnings) stepWarnings = warnings;
    if (applicationMode) {
        for (let i = 0; i < messages.length; i++) {
            stepWarnings[i] = warnings[i] + messages[i];
        }
    }
    else {
        stepWarnings = [0, 0, 0, 0, 0];
    }

    if (onclick) stepActions = onclick;

    let Steps = stepTitles.map(function (title, index) {
        return (
            <Step 
                key={index}
                number={index + 1}
                warning={stepWarnings[index]}
                title={title}
                active={index === (activeStep - 1)}
                markCompleted={((index + 1) < activeStep) && !applicationMode}
                onclick={stepActions[index]}
                applicationMode={applicationMode}
            />);
    });

    return (
        <div className={'ps-4 ' + (applicationMode ? 'bg-secondary' : "bg-case")}>
            <div className={'d-flex flex-row justify-content-around w55 py-1'}>
                {Steps}
            </div>
        </div>
    );
}

export default Stepper;
