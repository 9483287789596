import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useParams } from 'react-router-dom';
import { getApplicationContent, getApplicationMetadata, updateApplicationContent } from '../../services/Application';
import CaseDetails from './CaseDetails';
import CaseMessages from './CaseMessages';
import ApplicationDetails from './ApplicationDetails';
import LoadingSpinner from './LoadingSpinner';
import CertificateDetails from './CertificateDetails';
import CaseLog from './CaseLog';
import { getInstanceVariables } from '../../services/Process';

const ApplicationMetadata = ({ communicationHandler, registerCallback, displayModeHandler }) => {
    const { accessToken, checkPermission, userId } = useAuth();
    let { id } = useParams();

    const [application, setApplication] = useState(null);
    const [caseData, setCaseData] = useState(null);
    const [applicationData, setApplicationData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [caseLog, setCaseLog] = useState(null);
    const certificateDetailsVisibleStatuses = [14, 15, 16, 17, 18];

    const handleShippingInfoUpdate = (shippingInfo) => {
        let newApplication = application;
        newApplication.Step_5.Data.shippingInfo = shippingInfo;
        newApplication.Step_5.Data.shippingDateSent = new Date().toISOString().split('T')[0];
        setApplication(newApplication);
        updateApplicationContent(id, newApplication, accessToken);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await getApplicationContent(id, accessToken);
                if (response) {

                    setApplication(response);
                    const metadata = await getApplicationMetadata(id, accessToken);

                    setCaseData({
                        id: id,
                        number: metadata.number ? metadata.number : '#',
                        name: metadata.displayName,
                        createDate: metadata.createDate.split("T")[0],
                        createDateAndTime: metadata.createDate.split("T")[0] + " " + metadata.createDate.split("T")[1].split(".")[0],
                        statusId: metadata.status,
                        status: metadata.statusName,
                        exporterName: response.Step_1.Data.applicantName,
                        exporterNip: response.Step_1.Data.applicantNIP,
                        applicantName: metadata.Applicant.firstName + " " + metadata.Applicant.lastName,
                        applicantPhone: response.Step_1.Data.phoneNumber ? response.Step_1.Data.phoneNumber : 'Brak',
                        applicationNumber: metadata.applicationNumber ? metadata.applicationNumber : '#',
                        chamber: metadata.Chamber ? metadata.Chamber.name : 'Brak',
                        chamberId: metadata.Chamber ? metadata.Chamber.id : null,
                        operator: metadata.Operator ? (metadata.Operator.firstName + " " + metadata.Operator.lastName) : 'Brak',
                        operatorId: metadata.Operator ? metadata.Operator.id : null
                    });

                    setApplicationData({
                        id: id,
                        number: metadata.number ? metadata.number : '#',
                        name: metadata.displayName,
                        createDate: metadata.createDate.split("T")[0],
                        status: metadata.status,
                        statusName: metadata.statusName,
                        paymentPrint: response.Step_5.Data.printYes,
                        paymentAdditionalPrints: response.Step_5.Data.additionalPrints,
                        paymentShipping: response.Step_5.Data.shippingMethod,
                        paymentShippingCountry: response.Step_3.Data.receiverCountry,
                        paymentShippingAddress: response.Step_5.Data.ShippingAddress,
                        shippingDateSent: response.Step_5.Data.shippingDateSent,
                        shippingInfo: response.Step_5.Data.shippingInfo ? response.Step_5.Data.shippingInfo : 'Brak informacji o przesyłce',
                        SignedApplication: {
                            date: response.SignedDate ? response.SignedDate.split("T")[0] : null,
                            fileId: response.SignedFileId ? response.SignedFileId : null,
                            fileName: response.SignedFileName ? response.SignedFileName : null
                        },
                        PaymentConfirmation: {
                            date: response.PaymentDate ? response.PaymentDate.split("T")[0] : null,
                            fileId: response.PaymentFileId ? response.PaymentFileId : null,
                            fileName: response.PaymentFileName ? response.PaymentFileName : null
                        }
                    });

                    const instanceVariables = await getInstanceVariables(id, accessToken);
                    setCaseLog(instanceVariables);
                }


                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch application content:', error);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div className='w-100 p-4'><LoadingSpinner /></div>;
    }

    return (
        <div className='w-100 pb-4'>
            <CaseDetails
                caseData={caseData}
                displayModeHandler={displayModeHandler}
                displayMode={checkPermission('Obsługa wniosków') ? 'Operator' : 'Client'}
            />

            {certificateDetailsVisibleStatuses.includes(applicationData.status) && (
                <CertificateDetails
                    applicationData={applicationData}
                    displayMode={checkPermission('Obsługa wniosków') ? 'Operator' : (checkPermission('Obsługa wysyłki') ? 'Shipping' : 'Client')}
                    onShippingInfoUpdate={handleShippingInfoUpdate}
                />
            )}

            <ApplicationDetails
                applicationData={applicationData}
                displayModeHandler={displayModeHandler}
                displayMode={checkPermission('Obsługa wniosków') ? 'Operator' : (checkPermission("Obsługa wysyłki") ? 'Shipping' : 'Client')}
            />

            {!checkPermission('Obsługa wysyłki') && (
                <CaseMessages
                    caseData={caseData}
                />
            )}

            {checkPermission('Obsługa wniosków') && caseLog && (
                <CaseLog
                    logData={caseLog}
                />
            )}

            <div className='d-flex pt-3'>
                <span className='ms-4 text-black-50'>Wersja 0.0.2</span>
            </div>
        </div>
    )
}

export default ApplicationMetadata;