import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { ApiHost } from '../../utils/ApiConfig';

function FileUploadButton({ fileId, fileName, onChange, text = "Dodaj plik", className = "btn btn-outline-primary ms-0 w7 h2" }) {
    const [file, setFile] = useState(null);
    const [selectedFileName, setFileName] = useState(fileName || "Wybierz plik");

    const fileInputRef = useRef(null);

    const handleUpload = async () => {
        if (!file) {
            alert('Proszę wybrać plik przed wysłaniem.');
            return;
        }

        const maxSizeInMB = 50;
        if (file.size > maxSizeInMB * 1024 * 1024) {
            alert(`Plik jest za duży. Maksymalny rozmiar to ${maxSizeInMB}MB.`);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(ApiHost + '/common/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setFileName(file.name);

            onChange(response.data.file_id, file.name);
        } catch (error) {
            console.error('Wystąpił błąd podczas przesyłania pliku:', error);
        }
    };

    const getFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf':
                return 'application/pdf';
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            case 'gif':
                return 'image/gif';
            case 'txt':
                return 'text/plain';
            case 'doc':
                return 'application/msword';
            case 'docx':
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'pptx':
                return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            case 'zip':
                return 'application/zip';
            case 'rar':
                return 'application/x-rar-compressed';
            default:
                return 'application/octet-stream'; // Domyślny typ pliku
        }
    };

    const downloadFile = async (fileId) => {
        try {
            const response = await fetch(ApiHost + `/common/download/${fileId}`);
            if (!response.ok) {
                throw new Error('Błąd podczas pobierania pliku');
            }
            const blob = await response.blob();
            const fileExtType = getFileType(fileName);
            const downloadUrl = window.URL.createObjectURL(new Blob([blob], { type: fileExtType }));
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Błąd podczas pobierania pliku:', error);
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        if (file) {
            handleUpload();
        }
    }, [file]);

    return (
        <div>
            {(!fileId) ? (
                <div className='d-flex justify-content-between'>
                    <input type="file" onChange={handleFileChange} id="fileInput" accept=".pdf, image/*" ref={fileInputRef} style={{ display: "none" }} />
                    <div>
                        <button className={className} onClick={handleButtonClick}>{text}</button>
                    </div>

                    <div className='d-flex flex-row'>
                        <div>
                            <button style={{ display: "none" }} onClick={handleUpload} disabled={!file}>Prześlij plik</button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='d-flex justify-content-between'>
                    <div className='flex-fill'>
                        <label><a href="#" onClick={() => { downloadFile(fileId) }}>{selectedFileName}</a></label>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FileUploadButton;
