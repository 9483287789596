import React, { useEffect, useState } from 'react';
import ProductSelector from "./ProductSelector";
import ProductDetails from "./ProductDetails";
import { createEmptyFileData, createEmptyProductDetails } from '../../services/Application';
import FileUploadComponent from './FileUploadComponent';
import ValidationInfo from './ValidationInfo';
import { validateLettersDigitsSpecChars } from '../../utils/Validators';
import HelpTooltip from './HelpTooltip';
import ReadonlyOverlay from './ReadonlyOverlay';
import LoadingSpinner from './LoadingSpinner';
import PreviousValue from './PreviousValue';

const ProductionProcess = ({ index, name, data, previousData = {}, showPreviousData = false, productsList, onDataChange, onRemove, commentVisible = true, communicationHandler, registerCallback, viewAll = false, handleValidate, readOnly = false }) => {
    const [processData, setProcessData] = useState(data);
    const [selectedProduct, setSelectedProduct] = useState("");
    const [removedProducts, setRemovedProducts] = useState("");
    const [availableProducts, setAvailableProducts] = useState([]);
    const [processFiles, setProcessFiles] = useState([createEmptyFileData()]);
    const [productionProcessDescription, setProductionProcessDescription] = useState(data.productionProcessDescription);
    const [validators, setValidators] = useState({ productionProcessDescription: "", tariffCode: "" });
    const [loading, setLoading] = useState(true);
    const [fileDifferences, setFileDifferences] = useState(null);
    const [productDifferences, setProductDifferences] = useState(null);
    const tooltips = ["Proces produkcji każdego z produktów musi być opisany. Jeżeli nie masz dokumentu opisującego proces produkcji (w tym koniecznie kraj pochodzenia, kod taryfy celnej), musisz wypełnić pola poniżej. Jeżeli masz taki dokument, załącz go."]

    // Funkcje zwrotne do aktualizacji stanu komponentów
    const handleProductionProcessDescriptionChange = (e) => {
        setProductionProcessDescription(e.target.value);
        onDataChange({ target: { name: name, value: {id: data.id, productionProcessDescription: e.target.value, processFiles: processFiles, Products: processData.Products }, index: index } });
        setValidators({ ...validators, productionProcessDescription: (validateLettersDigitsSpecChars(e.target.value, 1000, (processFiles[0].name === "")) ? "false" : "true") });
    };

    const setValidator = (name, validator) => {
        setValidators(prevValidators => {
            return {
                ...prevValidators,
                [name]: validator
            };
        });
    };

    const removeValidator = (name) => {
        setValidators(prevValidators => {
            const updatedValidators = { ...prevValidators };
            delete updatedValidators[name];
            return updatedValidators;
        });
    };

    const handleProductionProcessFilesChange = () => {
        onDataChange({ target: { name: name, value: {id: data.id, productionProcessDescription: productionProcessDescription, processFiles: processFiles, Products: processData.Products }, index: index } });
    };

    const handleFileAdd = (fileType) => {
        switch (fileType) {
            case "processFiles":
                setProcessFiles(prevFiles => [...prevFiles, createEmptyFileData()]);
                break;
            default:
                break;
        }
    }

    const handleFileChange = (id, name, description, fileType, index, newFile = true) => {
        const fileInfo = createEmptyFileData();
        fileInfo.id = id;
        fileInfo.name = name;
        fileInfo.description = description;
        console.log("PP hFC");

        switch (fileType) {
            case "processFiles":
                setProcessFiles(prevFiles => {
                    const updatedFiles = [...prevFiles];
                    updatedFiles[index] = fileInfo;
                    if (newFile)
                        updatedFiles.push(createEmptyFileData());
                    return updatedFiles;
                });
                break;
            default:
        }
    }

    const handleFileRemove = (fileType, index) => {
        console.log("PP hFR: " + fileType + ", " + index);
        switch (fileType) {
            case "processFiles":
                setProcessFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
                break;
            default:
                break;
        }
    };

    const handleAddProductDetails = () => {
        setProcessData(prevProcessData => {
            const newProduct = createEmptyProductDetails();
            newProduct.name = selectedProduct;
            const updatedProducts = [...prevProcessData.Products, newProduct];
            onDataChange({ target: { name: name, value: { ...prevProcessData, Products: updatedProducts }, index: index } });
            return { ...prevProcessData, Products: updatedProducts };
        });
        setAvailableProducts(availableProducts.filter(prod => prod.productName !== selectedProduct));
        setSelectedProduct("");
    };

    const handleUpdateProductDetails = (productDetails, pdIndex) => {
        setProcessData(prevProcessData => {
            const updatedProducts = [...prevProcessData.Products];
            updatedProducts[pdIndex] = productDetails;
            onDataChange({ target: { name: name, value: { ...prevProcessData, Products: updatedProducts }, index: index } });
            return { ...prevProcessData, Products: updatedProducts };
        });
    };

    const handleRemoveProductDetails = (pIndex) => {
        setAvailableProducts([...availableProducts, { productName: processData.Products[pIndex].name }]);
        setRemovedProducts(prevIndexes => [...prevIndexes, pIndex]);
        removeValidator("productDetails_" + index + "_" + pIndex);
    };

    useEffect(() => {
        const filteredProducts = processData.Products.filter((_, index) => !removedProducts.includes(index));
        const updatedData = { ...processData, Products: filteredProducts };
        //onDataChange(updatedData, index);
        onDataChange({ target: { name: name, value: updatedData, index: index } });
    }, [removedProducts]);

    useEffect(() => {
        if (processFiles.length > 0) {
            handleProductionProcessFilesChange();
        }
        setValidators({ ...validators, productionProcessDescription: (validateLettersDigitsSpecChars(productionProcessDescription, 1000, (processFiles[0].name === "")) ? "false" : "true") });
    }, [processFiles]);

    const compareProducts = async () => {
        let differences = [];
        let differencesSummary = "";
        if (previousData.Products) {
            differences = previousData.Products.filter(prevProduct => !processData.Products.some(product => product.id === prevProduct.id));
        }
        if (differences.length > 0)
            differencesSummary = "<strong>Usunięte produkty:</strong><br/>" + differences.map(product => "- " + product.name).join(",<br/>");

        if (previousData.Products && previousData.Products.length > 0)
            differences = processData.Products.filter(product => !previousData.Products.some(prevProduct => prevProduct.id === product.id));
        if (differences.length > 0)
            differencesSummary += (differencesSummary.length > 0 ? "<br/>" : "") + "<strong>Dodane produkty:</strong><br/>" + differences.map(product => "- " + product.name).join(",<br/>");

        setProductDifferences(differencesSummary);
    }

    const compareFiles = async () => {
        //sprawdzanie różnic w plikach w wersji edytowanej i poprzedniej - usunięte pliki z poprzedniej wersji
        let differences = [];
        let differencesSummary = "";
        if (previousData.processFiles) {
            differences = previousData.processFiles.filter(prevFile => !data.processFiles.some(file => file.id === prevFile.id));
        }
        if (differences.length > 0)
            differencesSummary = "<strong>Usunięte pliki:</strong><br/>" + differences.map(file => "- " + file.name).join(",<br/>");

        //sprawdzanie różnic w plikach w wersji edytowanej i poprzedniej - nowe pliki w wersji edytowanej
        if (previousData.processFiles && previousData.processFiles.length > 0)
            differences = data.processFiles.filter(file => !previousData.processFiles.some(prevFile => prevFile.id === file.id));
        if (differences.length > 0)
            differencesSummary += (differencesSummary.length > 0 ? "<br/>" : "") + "<strong>Dodane pliki:</strong><br/>" + differences.map(file => "- " + file.name).join(",<br/>");

        setFileDifferences(differencesSummary);
    }

    const findMatchingPreviousProductDetails = (product) => {
        if (previousData.Products) {
            const matchingProduct = previousData.Products.find(prevProduct => prevProduct.id === product.id);
            if (matchingProduct) {
                return matchingProduct;
            }
        }
        return {};
    }
        
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            const excludedNames = processData.Products.map(product => product.name);
            const filteredProducts = productsList.filter(product =>
                !excludedNames.includes(product.productName)
            );
            await setAvailableProducts(filteredProducts);
            if (data.processFiles)
                await setProcessFiles(data.processFiles);

            await compareFiles();
            await compareProducts();
            setLoading(false);
        };

        loadData();
    }, []);

    useEffect(() => {
        let count = 0;
        for (const key in validators) {
            if (validators.hasOwnProperty(key) && ((validators[key] === "true") || validators[key] === true)) {
                count++;
            }
        }
        handleValidate("Process_" + index, (count === 0) ? "false" : "true");
    }, [validators]);

    return (
        <div className='card container-fluid shadow ms-4 my-2 py-3 px-0 w55 fade fade-in'>
            <div>
                <div className='d-flex flex-row px-4 pb-2 w-100 border-bottom fs-4'>
                    <div>
                        <strong>{"Proces produkcji nr " + (index + 1)}</strong>
                    </div>

                    {(!readOnly) && (
                        <div className='ms-auto'>
                            <button type="button" key={"ProcessRemove_" + index} className="btn btn-outline-primary rounded-2 w7 h2 ms-auto mb-0" onClick={() => onRemove(index)}>
                                Usuń proces
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className='d-flex flex-column px-4'>
                <ReadonlyOverlay readonly={readOnly}>
                    {availableProducts.length > 0 && (
                        <div className="d-flex flex-row pt-1 pb-2 mb-2 border-bottom">
                            <div className="flex-fill">
                                <ProductSelector name={"productSelector_" + index} data={selectedProduct} productsList={availableProducts} required="true" onDataChange={setSelectedProduct} label="Wybierz wszystkie produkty posiadające taki sam proces produkcji" tooltip={"Jeżeli któryś produkt ma inny proces produkcji, dodaj kolejny proces produkcji."} />
                            </div>

                            {(!readOnly) && (
                                <div className="mt-auto">
                                    <button type="button" className="btn btn-primary w7 h2 rounded-2 mt-auto ms-1 mb-0" disabled={selectedProduct === ""} onClick={handleAddProductDetails}>
                                        Dodaj produkt
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    <div className='d-flex flex-column mb-1'>
                        <div className="d-flex flex-row">
                            <h4>Opis procesu produkcji *</h4>
                            <HelpTooltip title={tooltips[0]} wide={true} />
                        </div>
                        <div>
                            Wypełnij pola poniżej lub załącz dokument zawierający szczegółowy opis procesu produkcji.
                        </div>
                    </div>

                    <PreviousValue show={showPreviousData && productDifferences} previousValue={productDifferences} header='Wprowadzone zmiany<hr/>'>
                        {processData.Products.map((product, pdIndex) => (
                            !removedProducts.includes(pdIndex) && (
                                <div className="pt-1 pb-2 border-bottom" key={"ProductDetailsDiv_" + index + "_" + pdIndex}>
                                    <ProductDetails
                                        index={pdIndex}
                                        key={"ProductDetails_" + index + "_" + pdIndex}
                                        name={"productDetails_" + index + "_" + pdIndex}
                                        data={product}
                                        previousData={findMatchingPreviousProductDetails(product)}
                                        showPreviousData={showPreviousData}
                                        onDataChange={handleUpdateProductDetails}
                                        onRemove={() => handleRemoveProductDetails(pdIndex)}
                                        commentVisible={commentVisible}
                                        communicationHandler={communicationHandler}
                                        registerCallback={registerCallback}
                                        viewAll={viewAll}
                                        handleValidate={setValidator}
                                    />
                                </div>
                            )
                        ))}
                    </PreviousValue>

                </ReadonlyOverlay>

                <div className='mt-2'>

                    <div>
                        <ReadonlyOverlay readonly={readOnly}>
                            <div className="mb-2">
                                <div className='d-flex align-items-center'>
                                    <label className="form-label">Szczegółowy opis procesu produkcji </label>
                                    <ValidationInfo title="Uzupełnij opis procesu produkcji (max 1000 znaków) lub dodaj załącznik z opisem." visible={validators.productionProcessDescription === "true" ? "true" : "false"} wide={true} />
                                </div>
                                <PreviousValue show={showPreviousData && previousData.productionProcessDescription && (previousData.productionProcessDescription !== productionProcessDescription)} previousValue={previousData.productionProcessDescription}>
                                    <textarea
                                        className="form-control my-0 h8 rounded-3"
                                        maxLength="1000"
                                        name={"Process_" + index + "_productionProcessDescription"}
                                        value={productionProcessDescription}
                                        onChange={handleProductionProcessDescriptionChange}
                                        placeholder="Szczegółowy opis procesu produkcji"
                                        invalid={validators.productionProcessDescription === "true" ? "true" : "false"}
                                        onBlur={handleProductionProcessDescriptionChange}
                                    />
                                </PreviousValue>
                            </div>

                            <div className='mb-1'>
                                <span>lub dodaj dokumenty ze szczegółowym opisem procesu produkcji</span>
                            </div>
                        </ReadonlyOverlay>

                        {loading ? (
                            <LoadingSpinner />
                        ) : (
                            <PreviousValue show={showPreviousData && fileDifferences} previousValue={fileDifferences} header='Wprowadzone zmiany<hr/>'>
                            {(processFiles.length > 0) && (
                            processFiles.map((file, fIndex) => (
                                <div className={fIndex > 0 ? 'mt-2' : 'mt-0'} key={"processFiles_" + index + "_" + fIndex}>
                                    <FileUploadComponent
                                        fileId={file.id}
                                        fileName={file.name}
                                        fileDescription={file.description}
                                        fileType="processFiles"
                                        index={fIndex}
                                        onChange={handleFileChange}
                                        onRemove={() => handleFileRemove("processFiles", fIndex)}
                                        readOnly={readOnly ? "true" : "false"}
                                        buttonLabel={fIndex === 0 ? "Dodaj plik" : "Dodaj kolejny plik"}
                                        />
                                </div>
                            )))}
                            </PreviousValue>
                        )}

                        {((processFiles.length < 1) && (!readOnly)) && (
                            <div>
                                <button type="button" className="btn btn-outline-primary w7 h2 rounded-2" onClick={() => { handleFileAdd("processFiles") }}>
                                    Dodaj plik
                                </button>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </div>);
}

export default ProductionProcess;