import React from 'react';
import { Tooltip } from 'bootstrap';

function ValidationInfo({title, visible, wide = false}) {
    // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    // tooltipTriggerList.map(function (tooltipTriggerEl) {
    //     return new Tooltip(tooltipTriggerEl)
    // })
  
    if (["false", false].includes(visible))
        return;
    return (
        // <div className='rounded-circle border p-0 d-flex justify-content-center align-items-center ms-1' style={{ width: '20px', height: '20px', borderWidth: '0px', color: 'white', cursor: 'help', backgroundColor: 'red' }} data-bs-toggle="tooltip" data-bs-placement="auto" data-bs-html="true" title={title}>
        <div className='rounded-circle border p-0 d-flex justify-content-center align-items-center tooltip' style={{ width: '1.5rem', height: '1.5rem', borderWidth: '0px', color: 'white', cursor: 'help', backgroundColor: 'red' }}>
            !
            <span className={'tooltiptext tooltip-error ' + (wide ? "tooltip-500" : "")} dangerouslySetInnerHTML={{__html: title}} />
        </div>
    );
}

export default ValidationInfo;