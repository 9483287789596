import React, { useState } from 'react';
import LoadingSpinner from './LoadingSpinner';
import CardHeader from './CardHeader';
import CasePayment from './CasePayment';
import CaseSignature from './CaseSignature';
import ProcessPanel from './ProcessPanel';

const ApplicationDetails = ({ applicationData, displayModeHandler, displayMode = "Client" }) => {
    const [applicationMode, setApplicationMode] = useState('read');
    // const paymentStatuses = [5, 6, 9, 11, 12];
    const paymentStatuses = [5, 6, 11, 12, 13, 14, 15, 16, 17, 18];
    const paymentConfirmedStatuses = [12, 13, 14, 15, 16, 17, 18];
    // const signatureStatuses = [9, 10, 12];
    const signatureStatuses = [10, 12, 13, 14, 15, 16, 17, 18];
    const signatureConfirmedStatuses = [13, 14, 15, 16, 17, 18];

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year} r.`;
    };

    useState(() => {
        if ((applicationData !== null) && (applicationData.status === 4)) {
            setApplicationMode('edit');
        }
    }, [applicationData]);

    if (!applicationData) {
        return (
            <div>
                <CardHeader text="Twój wniosek" onlyText="true" />
                <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                    <div className='d-flex flex-column w47'>
                        <LoadingSpinner text='Pobieranie danych wniosku' />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='w-100'>
            <CardHeader text={displayMode === "Client" ? "Twój wniosek" : "Wniosek"} onlyText="true" />
            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column'>
                    {displayMode !== "Shipping" && (
                        <div className='d-flex flex-row'>
                            <div>
                                <i className='bi bi-file-earmark-fill text-black-50 fs-1'></i>
                            </div>
                            <div className='ms-1'>
                                <strong>{applicationData.name}</strong><br />
                                <span className='text-black-50'>Utworzono {formatDate(applicationData.createDate)}</span>
                            </div>
                            <div className='ms-auto'>
                                {applicationMode === 'edit' ? (
                                    <button className='btn btn-primary ms-auto w11 h2' onClick={() => displayModeHandler(0)}>Edycja</button>
                                ) : (
                                    <button className='btn btn-outline-primary ms-auto w11 h2' onClick={() => displayModeHandler(0)}>Podgląd</button>
                                )}
                            </div>
                        </div>
                    )}

                    {paymentStatuses.includes(applicationData.status) && (
                        <CasePayment
                            status={paymentConfirmedStatuses.includes(applicationData.status) ? 'paid' : 'pending'}
                            applicationData={applicationData}
                            displayMode={displayMode}
                        />
                    )}
                    {signatureStatuses.includes(applicationData.status) && (
                        <CaseSignature
                            status={applicationData.status === 12 ? 'readyToSign' : signatureConfirmedStatuses.includes(applicationData.status) ? 'signed' : 'pending'}
                            id={applicationData.id}
                            signedApplication={applicationData.SignedApplication}
                            displayMode={displayMode}
                        />
                    )}
                    
                    <ProcessPanel embedded={true} />
                </div>
            </div>
        </div>
    );
};

export default ApplicationDetails;