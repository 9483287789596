import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import Product from './Product';
import ProductSummary from './ProductsSummary';
import { createEmptyApplication, getApplicationContent, updateApplicationContent, createEmptyProductData, createEmptySummary, getApplicationMetadata, getPreviousVersionContent, generateGUID } from '../../services/Application';
import InfoPopup from './InfoPopup';
import { clientCanEdit, operatorCanEdit } from './ReadOnly';
import ReadonlyOverlay from './ReadonlyOverlay';
import CardHeader from './CardHeader';
import { validateStep2 } from '../../utils/Validators';

const ApplicationStep2 = ({ communicationHandler, registerCallback, warningsCallback }) => {
    const { accessToken, checkPermission, userId } = useAuth();
    let { step, id } = useParams();
    let navigate = useNavigate();

    const [application, setApplication] = useState(createEmptyApplication());
    const [applicant, setApplicant] = useState({ producerName: "", producerCountry: "", producerStreet: "", producerBuilding: "", producerAppartment: "", producerPostCode: "", producerCity: "" });
    const [products, setProducts] = useState(application.Step_2.Data.Products);
    const [prevProducts, setPrevProducts] = useState([]);
    const [productsSummary, setProductsSummary] = useState(createEmptySummary);
    const [prevProductsSummary, setPrevProductsSummary] = useState(createEmptySummary());
    const [exportOnly, setExportOnly] = useState(false);
    const [infoPopupTrigger, setInfoPopupTrigger] = useState(0);

    const [warningPopupTrigger, setWarningPopupTrigger] = useState(0);
    const [errorDescription, setErrorDescription] = useState({ Errors: [], Disclaimer: "" });
    const [allowForwardOnError, setAllowForwardOnError] = useState(false);

    const [validators, setValidators] = useState({});

    const [status, setStatus] = useState(null);
    const [readOnly, setReadOnly] = useState(false);

    const debug = false;

    const handleForwardNavigation = () => {
        navigate('/application/edit/' + (parseInt(step) + 1) + '/' + id, { replace: true });
    }

    const setValidator = (name, validator) => {
        setValidators(prevValidators => {
            return {
                ...prevValidators,
                [name]: validator
            };
        });
    };

    const removeValidator = (name) => {
        setValidators(prevValidators => {
            const updatedValidators = { ...prevValidators };
            delete updatedValidators[name];
            return updatedValidators;
        });
    };

    const handleProductDataChange = (index, name, value) => {
        setProducts(prevProducts => {
            const updatedProducts = [...prevProducts];
            updatedProducts[index] = {
                ...updatedProducts[index],
                [name]: value
            };
            return updatedProducts;
        });
    };

    const handleRemoveProduct = (indexToRemove) => {
        setProducts(prevProducts => prevProducts.filter((product, index) => index !== indexToRemove));
        removeValidator("product_" + indexToRemove);
    };

    const setApplicationMode = async () => {
        const metadata = await getApplicationMetadata(id, accessToken);
        if (metadata) {
            setStatus(metadata.status);
        }

        if (checkPermission("Obsługa wniosków")) {
            if (!operatorCanEdit(metadata.status))
                setReadOnly(true);
        }
        else {
            if (!clientCanEdit(metadata.status))
                setReadOnly(true);
        }
    }

    const findMatchingPreviousProduct = (product) => {
        let prevProduct = prevProducts.find(prevProduct => prevProduct.id === product.id);
        if (prevProduct) {
            return prevProduct;
        }
        return {};
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (debug) console.log("Pobieranie danych");
                const response = await getApplicationContent(id, accessToken);
                const prevApplication = await getPreviousVersionContent(id, accessToken);
                if (debug) console.log(response.Step_2);
                if (response.Step_2) {
                    setProducts(response.Step_2.Data.Products || []);
                    setProductsSummary(response.Step_2.Data.Summary || {});
                    setApplication(response);

                    if (response.Step_1) {
                        setApplicant({
                            producerName: response.Step_1.Data.applicantName,
                            producerCountry: "PL",
                            producerStreet: response.Step_1.Data.applicantStreet,
                            producerBuilding: response.Step_1.Data.applicantBuilding,
                            producerAppartment: response.Step_1.Data.applicantAppartment,
                            producerPostCode: response.Step_1.Data.applicantPostCode,
                            producerCity: response.Step_1.Data.applicantCity
                        });
                        setExportOnly(response.Step_1.Data.exporterIsNotProducer);
                    }
                }

                if (prevApplication && prevApplication.Step_2) {
                    setPrevProducts(prevApplication.Step_2.Data.Products || []);
                    setPrevProductsSummary(prevApplication.Step_2.Data.Summary || {});
                }

                setApplicationMode();

            } catch (error) {
                console.error('Failed to fetch application content:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        //if (debug) console.log("AS2 - uE");
        updateProductsSummaries();
    }, [products, productsSummary.editSummaries, productsSummary.totalQuantity, productsSummary.totalNetWeight, productsSummary.totalGrossWeight]);

    const countWarnings = () => {
        let count = 0;
        for (const key in validators) {
            if (validators.hasOwnProperty(key) && ((validators[key] === "true") || validators[key] === true)) {
                count++;
            }
        }
        return count;
    }

    useEffect(() => {
        warningsCallback(1, countWarnings());
    }, [validators]);

    const updateProductsSummaries = () => {
        if (productsSummary.editSummaries === "true" || productsSummary.editSummaries === true) return;
        let newProductsSummaryEdit = "";
        let newQuantitySummaryEdit = "";
        let i = 1;
        products.forEach(product => {
            //if (debug) console.log("PN: " + product.productName);
            newProductsSummaryEdit += i + ". " + product.productName + " (" + product.originCountry + ")\n";
            newQuantitySummaryEdit += product.quantity + " " + (product.unit ? product.unit : "") + (product.quantityDesc ? (" " + product.quantityDesc) : "") + "\n";
            i++;
        });

        if ((productsSummary.totalQuantity) && (productsSummary.totalQuantity.length > 0))
            newQuantitySummaryEdit += "Total: " + productsSummary.totalQuantity + "\n";
        if ((productsSummary.totalNetWeight) && (productsSummary.totalNetWeight.length > 0))
            newQuantitySummaryEdit += "Total net weight: " + productsSummary.totalNetWeight + "\n";
        if ((productsSummary.totalGrossWeight) && (productsSummary.totalGrossWeight.length > 0))
            newQuantitySummaryEdit += "Total gross weight: " + productsSummary.totalGrossWeight + "\n";

        handleProductSummaryDataChange("productsSummaryEdit", newProductsSummaryEdit);
        handleProductSummaryDataChange("quantitySummaryEdit", newQuantitySummaryEdit);
    }

    const handleProductSummaryDataChange = (name, value) => {
        //if (debug) console.log("hPSDC: [" + name + "]: " + value)
        setProductsSummary(prevSummary => {
            return {
                ...prevSummary,
                [name]: value
            };
        });
    };

    const handleAddProduct = () => {
        // Funkcja obsługująca dodawanie nowego produktu
        setProducts(prevProducts => [...prevProducts, createEmptyProductData()]);
    };

    const validateData = async (application, e) => {
        const errorDescription = { Errors: [], Disclaimer: "" }

        let validationInfo = validateStep2(application.Step_2.Data);

        if (validationInfo.length > 0) {
            errorDescription.Errors.push({ Header: `Na bieżącej stronie wniosku stwierdzono następujące błędy (${validationInfo.length}):`, Description: validationInfo });
            errorDescription.Disclaimer = "Dane wprowadzone we wniosku wymagają poprawy. Możesz kontynuować wypełnianie wniosku, jednak przed jego wysłaniem konieczna będzie korekta.";
            setWarningPopupTrigger(warningPopupTrigger + 1);
        }
        setErrorDescription(errorDescription);

        warningsCallback(1, validationInfo.length);
        if (e.target.name !== "forward")
            setAllowForwardOnError(false);
        if ((validationInfo.length === 0) && (e.target.name === "forward"))
            handleForwardNavigation();
    };

    const handleSave = async (e) => {
        // Funkcja zwracająca dane komponentów Product w postaci JSON
        const productDataJSON = products.map(product => ({
            id: product.id ? product.id : generateGUID(),
            productName: product.productName,
            quantity: product.quantity,
            unit: product.unit,
            quantityDesc: product.quantityDesc,
            tariffCode: product.tariffCode,
            originCountry: product.originCountry,
            producerName: product.producerName,
            producerCountry: product.producerCountry,
            producerStreet: product.producerStreet,
            producerBuilding: product.producerBuilding,
            producerAppartment: product.producerAppartment,
            producerPostCode: product.producerPostCode,
            producerCity: product.producerCity,
            producer: product.producer,
            exporter: product.exporter,
            boughtInEU: product.boughtInEU,
            boughtOutsideEU: product.boughtOutsideEU,
            importClearanceFile: product.importClearanceFile
        }));

        const productsSummaryJSON = {
            totalQuantity: productsSummary.totalQuantity,
            totalNetWeight: productsSummary.totalNetWeight,
            totalGrossWeight: productsSummary.totalGrossWeight,
            transportDetails: productsSummary.transportDetails,
            productsRemarks: productsSummary.productsRemarks,
            productsSummaryEdit: productsSummary.productsSummaryEdit,
            quantitySummaryEdit: productsSummary.quantitySummaryEdit,
            editSummaries: productsSummary.editSummaries
        };

        let app = application;
        app.Step_2.Data.Products = productDataJSON;
        app.Step_2.Data.Summary = productsSummaryJSON;
        updateApplicationContent(id, app, userId, accessToken);

        await validateData(app, e);

        setInfoPopupTrigger(infoPopupTrigger + 1);
    };

    const handleForward = async (e) => {
        e.preventDefault();
        await setAllowForwardOnError(true);
        handleSave(e);
    };

    const handleBack = async (e) => {
        // Funkcja obsługująca przekierowanie do poprzedniego kroku
        e.preventDefault();
        navigate('/application/edit/' + (parseInt(step) - 1) + '/' + id, { replace: true });
    };

    return (
        <div className='pb-4'>

            <CardHeader
                text="Produkty / Towary - dodawanie"
                commentId="2-1"
                commentVisible={status !== 1}
                communicationHandler={communicationHandler}
                registerCallback={registerCallback}
                viewAll={checkPermission("Obsługa wniosków")}
            />
            <div className='ms-4 w55'>
                <div>
                    W tej sekcji prosimy Cię o określenie które produkty wyprodukowałeś we własnej firmie, a które towary zakupiłeś od innych przedsiębiorców. Ponieważ w ramach jednej transakcji możesz eksportować produkty własnej produkcji oraz towary zakupione od innych przębiorców, w tej sekcji odrębnie podajesz dane dla produktów i towarów.<br />
                    <strong> Pamiętaj, aby uzupełnić poszczególne pola w języku, w jakim mają się pojawić na świadectwie.</strong>
                </div>
                <div className='d-flex flex-column w47'>
                    <div>
                    </div>
                    {(products.length === 0) && (
                        <div className="d-flex flex-row">
                            <button type="button" className="btn btn-primary w11 h2 rounded-2 ms-auto" onClick={handleAddProduct}>
                                Dodaj produkt
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {products.map((product, index) => (
                <div key={index} className='card container-fluid shadow ms-4 mt-2 px-4 py-3 w55'>
                    <div className='d-flex flex-column w47 fade fade-in'>
                        <Product
                            index={index}
                            data={product}
                            previousData={findMatchingPreviousProduct(product)}
                            showPreviousData={checkPermission("Obsługa wniosków")}
                            applicant={applicant}
                            exportOnly={exportOnly}
                            onDataChange={(name, value) => handleProductDataChange(index, name, value)}
                            onRemove={() => handleRemoveProduct(index)}
                            commentVisible={status !== 1}
                            communicationHandler={communicationHandler}
                            registerCallback={registerCallback}
                            viewAll={checkPermission("Obsługa wniosków")}
                            handleValidate={setValidator}
                            readOnly={readOnly}
                        />
                    </div>
                </div>
            ))}

            {((!readOnly) && (products.length > 0)) && (
                <div className="d-flex flex-row ms-4 mt-2 w55">
                    <button type="button" className="btn btn-primary w11 h2 rounded-2 ms-auto" onClick={handleAddProduct}>
                        Dodaj kolejny produkt
                    </button>
                </div>
            )}

            {(productsSummary && prevProductsSummary) && (
                <div>
                    <CardHeader
                        text="Podsumowanie"
                        commentId="2-2"
                        commentVisible={status !== 1}
                        communicationHandler={communicationHandler}
                        registerCallback={registerCallback}
                        viewAll={checkPermission("Obsługa wniosków")}
                    />
                    <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                        <div className='d-flex flex-column w47'>
                            <ReadonlyOverlay readonly={readOnly}>
                                <ProductSummary
                                    data={productsSummary}
                                    previousData={prevProductsSummary}
                                    showPreviousData={checkPermission("Obsługa wniosków")}
                                    onDataChange={(name, value) => handleProductSummaryDataChange(name, value)}
                                    communicationHandler={communicationHandler}
                                    registerCallback={registerCallback}
                                />
                            </ReadonlyOverlay>
                        </div>
                    </div>
                </div>
            )}

            {!readOnly && (
                <div className="flex-column mt-3">
                    <form>
                        <div className="d-flex flex-row ms-4 w55">
                            <button
                                className="btn btn-outline-primary rounded-2 me-auto w11 h2"
                                onClick={handleBack}
                            >
                                Wstecz
                            </button>

                            <button
                                type="button"
                                name="save"
                                className="btn btn-outline-primary rounded-2 w11 h2"
                                onClick={handleSave}
                            >
                                Zapisz wersję roboczą
                            </button>

                            <button
                                type="button"
                                name="forward"
                                className="btn btn-primary rounded-2 ms-1 w11 h2"
                                onClick={handleForward}
                            >
                                Zapisz i przejdź dalej
                            </button>
                        </div>
                    </form>
                </div>
            )}
            <InfoPopup title={"Zapisano wersję roboczą wniosku."} trigger={infoPopupTrigger} />
            <InfoPopup
                title={""}
                errorDescription={errorDescription}
                trigger={warningPopupTrigger}
                errorMode={true}
                actionOnAccept={handleForwardNavigation}
                allowForward={allowForwardOnError}
            />
        </div>
    );
};

export default ApplicationStep2;
