import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { getApplicationComment } from '../../services/Application';

function CommunicationSpot({ commentId, location, isVisible, onClickHandler, registerCallback, viewAll = false }) {

    const [color, setColor] = useState("secondary");
    const [trigger, setTrigger] = useState(0);
    const [data, setData] = useState();
    let { step, id } = useParams();
    const { accessToken } = useAuth();
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState("none");
    const [active, setActive] = useState(false);

    const debug = false;

    const handleClick = () => {
        setTrigger(trigger + 1);
        onClickHandler(commentId, commentId + "-" + trigger, data);
    }

    const handleStatusChange = (newStatus) => {
        if (debug) console.log("hSC: newStatus: " + newStatus);

        if (newStatus === "active" || newStatus === "inactive") {
            setActive(newStatus === "active");
            return;
        }

        switch (newStatus) {
            case "open":
                setColor("danger");
                break;
            case "solved":
                setColor("success");
                break;
            default:
                setColor("secondary");
        }
    };

    useEffect(() => {
        const fetchCommentData = async () => {
            if (debug) console.log("Pobieranie danych komentarza: " + commentId);
            try {
                let commentData = await getApplicationComment(id, step, commentId, accessToken);
                if (location)
                    commentData.locationName = location;
                setData(commentData);
                setState(commentData.status);

                switch (commentData.status) {
                    case "open":
                        setColor("danger");
                        break;
                    case "solved":
                        setColor("success");
                        break;
                    default:
                        setColor("primary");
                }

                setTrigger(0);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching comment data:", error);
                setLoading(false);
            }
        };

        fetchCommentData();
    }, [commentId, id, step]);

    useEffect(() => {
        if (!loading) {
            if (debug) {
                console.log("Pobrano dane komentarza id: " + commentId);
                console.log(JSON.stringify(data));
            }
        }
    }, [loading, data, commentId]);

    useEffect(() => {
        if (debug) console.log("Rejestracja callback - id: " + commentId);
        registerCallback(commentId, handleStatusChange);
    }, []);

    if (isVisible && (viewAll || state === "open" || state === "solved"))
        return (
            <div className='ms-auto tooltip z-2'>
                {
                    loading ? (
                        <button className={"btn btn-secondary p-0 me-auto w2 h2"} title="Ładowanie danych...">
                            ...
                        </button>
                    ) : (
                        <button className={"btn " + "btn-outline-" + color + " p-0 me-auto w2 h2 border-" + color + (active ? " border-3" : " border-1")} onClick={() => { handleClick(); setActive(true); }}>
                            <i className='bi-envelope fs-3'></i>
                            <span className='tooltiptext tooltip-left'>Kliknij, aby otworzyć konwersację</span>
                        </button>
                    )
                }
            </div>
        );
}

export default CommunicationSpot;