import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import DataTable from './DataTable';
import { useNavigate } from 'react-router-dom';
import { activateUser, blockUser, getUsersList } from '../../services/User';

const UserList = () => {
    const { accessToken } = useAuth();
    const { userId } = useAuth();
    const [users, setUsers] = useState();
    let navigate = useNavigate();
    const itemsPerPage = 10;
    const columnHeaders = ['Nazwisko', 'Imię', 'Email', 'Izba', 'Aktywny', 'Data utworzenia', 'Operacje'];
    const dataColumns = ['LastName', 'FirstName', 'Email', 'Chamber', 'Active', 'CreateDate', 'Id'];
    const actionNames = ['Edytuj', 'Aktywuj', 'Blokuj'];

    const activateUserHandler = (userId) => {
        if (activateUser(userId)){
            fetchData();
        }
    }

    const blockUserHandler = (userId) => {
        if (blockUser(userId)){
            fetchData();
        }
    }

    const editUserHandler = (userId) => {
        navigate('/user/edit/' + userId, { replace: true });
    }

    const calculateOperations = (user) => {
        let operations = [2, 0, 0];     // Status poszczególnych operacji (Edytuj, Aktywuj, Blokuj)
                                        // 0 - ukryte, 1 - wyłączone, 2 - włączone
        if (user.Active)
            operations = [2, 0, 2];
        if (!user.Active)
            operations = [1, 2, 0];

        return operations;
    }

    const actionHandlers = [editUserHandler, activateUserHandler, blockUserHandler];

    const fetchData = async () => {
        let tempUsers = await getUsersList();
        let usersWithOperations = [];
        tempUsers.forEach(user => {
            let userWithOperation = {...user, Operations: calculateOperations(user)};
            usersWithOperations.push(userWithOperation);
        });
        
        setUsers(usersWithOperations);
    };
    useEffect(() => {
    },[users]);

    useEffect(() => {

        fetchData();
    }, [userId, accessToken]);

    return (
        <div className='card container-fluid shadow ms-4 px-4 py-3 w80'>
            <div className='d-flex flex-column w72'>
                <div>
                    W tym widoku możesz zarządzać użytkownikami systemu.
                </div>
                <div className='ms-auto pb-3'>
                <button className="btn btn-primary" style={{ width: '140px' }} onClick={() => {navigate('/user/add', { replace: true });}}>Dodaj</button>
                </div>
            </div>

            {users && (
            <DataTable
                data={users}
                itemsPerPage={itemsPerPage}
                columnCount={columnHeaders.length - 1}
                columnHeaders={columnHeaders}
                dataColumns={dataColumns}
                actionNames={actionNames}
                actionHandlers={actionHandlers}
                initialSortColumn="Nazwisko"
                />
            )}
        </div>
    );
};

export default UserList;
