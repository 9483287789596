import React, { useState, useEffect } from 'react';
import ComponentSelector from './ComponentSelector';

function FileComponentsConnector({ index, data = [], componentsList, fileType, onDataChange, readOnly = "false" }) {
    const [components] = useState(componentsList.sort((a, b) => a.localeCompare(b)));
    const [connectedComponents, setConnectedComponents] = useState(data.sort((a, b) => a.localeCompare(b)));
    const [filter, setFilter] = useState("");
    const [showSelector, setShowSelector] = useState(false);

    if (showSelector === false) {
        return (
            <div className='w-100'>
                {connectedComponents.length > 0 ? (
                    <div className='d-flex flex-column'>
                        <span className='mb-1'>Powiązane materiały, surowce lub komponenty:</span>
                        <ul>
                            {connectedComponents.map((component, indx) => (
                                <li key={"connectedComponent_" + indx}>
                                    {component}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div className=''>
                        Brak powiązanych materiałów, surowców lub komponentów.
                    </div>
                )}
                {[false, "false"].includes(readOnly) && (
                    <button type="button" className="btn btn-outline-primary w21 mt-1" onClick={() => setShowSelector(true)}>
                        Wybierz komponenty, których dotyczy ten dokument
                    </button>
                )}
            </div>
        );
    }

    return (
        <div className='w-100'>
            <input type="text" className="form-control" placeholder="Filtruj materiały, surowce lub komponenty" value={filter} onChange={(e) => setFilter(e.target.value)} />
            {(((components.length > 0) && ([false, "false"].includes(readOnly))) ? (
                components.filter((component) => component.toLowerCase().includes(filter.toLowerCase())).map((component, indx) => (
                    <div key={"connectedComponent_" + indx} className='d-flex flex-row mt-1'>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={"connectedComponent_" + indx}
                            value={component}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setConnectedComponents(prevComponents => {
                                        return [...prevComponents, component];
                                    });
                                    onDataChange([...connectedComponents, component], fileType, index);
                                } else {
                                    setConnectedComponents(prevComponents => {
                                        return prevComponents.filter((comp) => comp !== component);
                                    });
                                    onDataChange(connectedComponents.filter((comp) => comp !== component), fileType, index);
                                }

                            }}
                            checked={connectedComponents.includes(component)}
                        />
                        <label className="form-check-label" htmlFor={"connectedComponent_" + indx}>{component}</label>
                    </div>
                ))
            ) : (
                <div className='d-flex flex-row mb-1'>
                    Materiały, surowce lub komponenty, których dotyczy ten dokument
                </div>
            ))
            }
            <button type="button" className="btn btn-outline-primary rounded-2 w7 h2 mt-1" onClick={() => setShowSelector(false)}>
                Zatwierdź
            </button>
        </div>
    );
/*    const debug = false;

    const handleSelect = (e) => {
        setSelectedComponent(e);
    }

    const handleAdd = (e) => {
        setConnectedComponents(prevComponents => {
            return [...prevComponents, selectedComponent];
        });

        setComponents(prevComponents => {
            return prevComponents.filter((component) => component !== selectedComponent);
        });

        onDataChange([...connectedComponents, selectedComponent], fileType, index);
    }

    const handleRemove = (componentName) => {
        setConnectedComponents(prevComponents => {
            return prevComponents.filter((component) => component !== componentName);
        });

        setComponents(prevComponents => {
            return [...prevComponents, componentName];
        });

        setSelectedComponent("");
        onDataChange(connectedComponents.filter((component) => component !== componentName), fileType, index);
    }

    useEffect(() => {
        if (debug) console.log("uE, FCC, data: " + JSON.stringify(data));
        if (data) {
            setConnectedComponents(data);
            data.forEach(component => {
                setComponents(prevComponents => {
                    return prevComponents.filter((comp) => comp !== component);
                });
            });
        }
    }, [])

    useEffect(() => {
    }, [components]);

    if (connectedComponents === undefined || (connectedComponents.length === 0 && readOnly === 'true')) return null;

    return (
        <div className='w-100'>
            {(((components.length > 0) && (readOnly === "false")) ? (
                <div className='d-flex flex-row'>
                    <div className='flex-fill'>
                        <ComponentSelector label="Wybierz materiały, surowce lub komponenty" componentsList={components} data={selectedComponent} onDataChange={handleSelect} />
                    </div>
                    <button type="button" className="btn btn-outline-primary rounded-2 mt-auto mb-0 ms-1 w7 h2" onClick={handleAdd}>
                        Dodaj
                    </button>
                </div>
            ) : (
                <div className='d-flex flex-row mb-1'>
                    Materiały, surowce lub komponenty, których dotyczy ten dokument
                </div>
            ))
            }

            <div className='d-flex flex-wrap'>
                {connectedComponents.length > 0 ? (
                    connectedComponents.map((component, index) => (
                        <div key={"connectedComponent_" + index} className='d-flex flex-row btn btn-outline-primary border rounded-2 mt-1 me-1'>
                            {component}
                            {(readOnly === "false") && (
                                <button type="button" className="btn btn-outline-danger p-0 rounded-2 align-self-start ms-1 z-2" style={{ width: '20px', border: "0" }} onClick={() => handleRemove(component)} title="Usuń z listy">
                                    X
                                </button>
                            )}
                        </div>
                    ))
                ) : (
                    <div className='my-1'>
                        Brak połączonych materiałów, surowców lub komponentów.
                    </div>
                )}
            </div>
        </div>
    );*/
}

export default FileComponentsConnector;
