import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { appendSignedApplication, getSignedApplication } from '../../services/Application';
import { commitStep } from '../../services/Process';
import FileUploadButton from './FileUploadButton';
import LoadingSpinner from './LoadingSpinner';
import { useAuth } from '../../context/AuthContext';

const CaseSignature = ({ status, id, signedApplication = null, displayMode = null }) => {
    const [showSignatureDetails, setShowSignatureDetails] = useState(false);
    const [applicationDownloaded, setApplicationDownloaded] = useState(false);
    const [signatureInProgress, setSignatureInProgress] = useState(false);
    const [signatureFileId, setSignatureFileId] = useState(signedApplication.fileId);
    const [signatureFileName, setSignatureFileName] = useState(signedApplication.fileName);
    const {accessToken, userName} = useAuth();
    const [loading, setLoading] = useState(false);

    const debug = false;

    const getStatusMessage = (status) => {
        switch (status) {
            case 'signed':
                return 'Wniosek podpisany';
            case 'pending':
                return 'Wniosek oczekuje na weryfikację podpisu';
            case 'readyToSign':
                if (signedApplication.date)
                    return 'Wniosek podpisany niepoprawnie';
                return 'Wniosek niepodpisany';
            case 'failed':
                return 'Podpis nie powiódł się';
            default:
                return 'Nieznany status';
        }
    };

    const handleSignedApplicationAdd = async (signedFileId, signedFileName) => {
        setSignatureInProgress(true);

        setSignatureFileId(signedFileId);
        setSignatureFileName(signedFileName);
        await appendSignedApplication(id, signedFileId, signedFileName);
        await performCommit();

        setSignatureInProgress(false);
    };

    const handleGetApplication = async () => {
        setLoading(true);
        await getSignedApplication(id);
        setApplicationDownloaded(!applicationDownloaded);
        setLoading(false);
    };

    const performCommit = async () => {
        try {
            const result = await commitStep(id, userName, accessToken);
            window.location.reload();
        } catch (error) {
            console.error("Error committing step:", error);
        }
    };

    useEffect(() => {
        if (status !== 'signed') {
            setShowSignatureDetails(true);
        }
    }, [status]);

    if (displayMode === 'Shipping') {
        return null;
    }
    
    return (
        <div className='border-top pt-1 mt-1'>
            <div className='w-100 d-flex flex-row align-items-center'>
                <div>
                    <i className={`fs-1 bi bi-${status === 'signed' ? 'check-circle-fill text-success' : ((status === 'readyToSign') ? 'x-circle-fill text-danger' : 'question-circle-fill text-warning')}`} />
                </div>

                <div className='ms-1'>
                    <strong>{getStatusMessage(status)}</strong><br />
                    {(signedApplication.date) && (
                        <span className='text-black-50'>Podpisano {signedApplication.date && (signedApplication.date.split(" ")[0] || "-")}</span>
                    )}
                </div>

                <div className='ms-auto'>
                    <button className='btn btn-outline-primary ms-auto w11 h2' onClick={() => setShowSignatureDetails(!showSignatureDetails)}>{showSignatureDetails ? 'Ukryj' : 'Pokaż'} szczegóły podpisu</button>
                </div>
            </div>

            {showSignatureDetails && (
                <div>
                    {(status === 'readyToSign') && (<div>
                        <div className="pt-1">
                            {signedApplication.date && (<strong>Podpis w Twoim wniosku wymaga poprawy.<br/></strong>)}
                            Możesz teraz pobrać gotowy wniosek. <strong>Podpisz go elektronicznie</strong> zgodnie z reprezentacją i załaduj podpisany plik korzystając z przycisku “Dodaj podpisany wniosek”.<br /><br />
                            Akceptujemy następujące formy podpisów:<br /><br />
                            <ul>
                                <li>
                                    <strong>Darmowy profil zaufany</strong> - poprzez rządową stronę: <a href="https://www.gov.pl/web/gov/podpisz-dokument-elektronicznie-wykorzystaj-podpis-zaufany" target='_blank'>https://www.gov.pl/web/gov/podpisz-dokument-elektronicznie-wykorzystaj-podpis-zaufany</a>
                                </li>
                                <li>
                                    <strong>Podpis kwalifikowany</strong> - jeśli wykupiłeś go u jednego z dostawców: <a href="https://www.biznes.gov.pl/pl/portal/0075" target='_blank'>https://www.biznes.gov.pl/pl/portal/0075</a>
                                </li>
                            </ul>
                        </div>

                        <div className='w-100 d-flex flex-row mt-2'>
                            {loading ? (
                                <div className="btn btn-outline-primary ms-auto my-0 h2 w11">
                                    <LoadingSpinner text='Pobieranie wniosku' />
                                </div>
                            ) : (
                                <button className="btn btn-outline-primary ms-auto my-0 h2 w11" type="button" onClick={handleGetApplication}>Pobierz wniosek</button>
                            )}
                            {!signatureInProgress ? (
                                <FileUploadButton
                                    className={"btn " + (applicationDownloaded ? "btn-primary" : "btn-outline-primary") + " ms-1 my-0 h2 w11"}
                                    text={"Dodaj podpisany wniosek"}
                                    onChange={handleSignedApplicationAdd}
                                />
                            ) : (
                                <div className="btn btn-primary ms-1 my-0 h2 w11" role="status">
                                    <LoadingSpinner text='Przetwarzanie' />
                                </div>
                            )}
                        </div>
                    </div>)}

                    {((!signatureInProgress) && signatureFileId && signatureFileName) && (
                        <div className='w-100 d-flex flex-row mt-1'>
                            <span className='w9'><strong>Podpisany wniosek</strong></span>
                            <FileUploadButton
                                className={"btn " + (applicationDownloaded ? "btn-primary" : "btn-outline-primary") + " ms-1 my-0 h2 w11"}
                                fileId={signatureFileId}
                                fileName={signatureFileName}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

CaseSignature.propTypes = {
    status: PropTypes.oneOf(['signed', 'pending', 'readyToSign', 'failed']).isRequired,
};

export default CaseSignature;