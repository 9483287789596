import React, { useEffect } from 'react';
import { useState } from 'react';
import StatusBadge from './StatusBadge';

const StatusTagSelect = ({data, onChange, locked = false}) => {
    const [statuses, setStatuses] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [showFilter, setShowFilter] = useState(false);

    const tempStatuses = [
        'nowy',
        'roboczy',
        'wysłano',
        'weryfikacja',
        'do poprawy',
        'weryfikacja opłaty',
        'opłata za usługę',
        'do opłacenia',
        'do anulowania',
        'anulowany',
        'do podpisu',
        'do opłaty i podpisu',
        'weryfikacja podpisu',
        'do wydania',
        'do wydruku',
        'do odbioru',
        'do wysyłki',
        'wystawione'
    ];

    useEffect(() => {
        setStatuses(tempStatuses);
        setSelectedStatuses(data);
    }, [data]);

    useEffect(() => {
        if (onChange) {
            if (JSON.stringify(selectedStatuses) !== JSON.stringify(data))
                onChange(selectedStatuses);
        }
    }, [selectedStatuses]);

    return (
        <div>
            <div
                className="d-flex flex-wrap form-control text-black-50"
                onClick={() => {if (!locked) setShowFilter(!showFilter)}}>
                {selectedStatuses.length === 0 && 'Kliknij, aby wybrać statusy do filtrowania'}
                {selectedStatuses.map((status, index) => (
                    <div key={index} className="p-0-5">
                        <StatusBadge text={status} key={index} colorMode='Operator' />
                    </div>
                ))}
            </div>
            {showFilter && (
                <div className="d-flex flex-column border rounded-bottom-3 p-1">
                    <div className="d-flex flex-row">
                        <label className="form-label">Wybierz statusy do filtrowania</label>
                        <span className="ms-auto" onClick={() => setShowFilter(false)}>^ Ukryj</span>
                    </div>
                    <div className="d-flex flex-wrap">
                        {tempStatuses.map((status, index) => (
                            <div className="d-flex flex-row border rounded-3 p-0-5 ms-1 mb-1" key={index}>
                                <input
                                    type="checkbox"
                                    className='form-check-input small me-1 align-self-center'
                                    id={status}
                                    name={status}
                                    value={status}
                                    checked={selectedStatuses.includes(status)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedStatuses([...selectedStatuses, e.target.value]);
                                        } else {
                                            setSelectedStatuses(selectedStatuses.filter(item => item !== e.target.value));
                                        }
                                    }} />
                                <div htmlFor={status}>
                                    <StatusBadge text={status} colorMode='Operator' />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="d-flex flex-row">
                    <button className="btn btn-primary w7 ms-auto h2" onClick={() => setShowFilter(false)}>Zamknij</button>
                    <button className="btn btn-outline-primary w7 ms-1 h2" onClick={() => setSelectedStatuses([])}>Wyczyść</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StatusTagSelect;