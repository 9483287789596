import React, { useState, useEffect } from 'react';
import Sidebar from '../components/layout/Sidebar'
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import CardHeader from '../components/common/CardHeader';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { commitStep } from '../services/Process';
import Payment from '../services/Payment';

function PaymentFinish() {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const { applicationId } = useParams();
    const { accessToken, userName } = useAuth();
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [retryCount, setRetryCount] = useState(1);

    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const handleResize = () => {
        if (window.innerWidth >= 992) {
            setIsSidebarVisible(true);
        } else {
            setIsSidebarVisible(false);
        }
    };

    const verifyPayment = async () => {
        console.log('verifyPayment');
        const response = await Payment.verify(applicationId, accessToken);
        if (response) {
            console.log(response);
            console.log(response.status);
            setPaymentStatus(response.status);
            setRetryCount(retryCount + 1);
        }
    }

    const redirectToApplication = () => {
        navigate(`/application/edit/1/${applicationId}`);
    }

    const redirectToApplicationsList = () => {
        navigate(`/application/list`);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        const fetchData = async () => {
            setLoading(true);
            setPaymentStatus('PENDING');
            //const response = await Payment.verify(applicationId, accessToken);
            //await verifyPayment();
            //console.log(response);
            // await commitStep(applicationId, userName, accessToken);
            // navigate(`/application/edit/1/${applicationId}`);
            setLoading(false);
        };

        fetchData();

        return () => window.removeEventListener('resize', handleResize); // Oczyszczenie przy demontowaniu
    }, []);

    useEffect(() => {
        switch (paymentStatus) {
            case 'CANCELED':
                setTimeout(() => redirectToApplication(), 10000);
                break;
            case 'COMPLETED':
                commitStep(applicationId, userName, accessToken);
                setTimeout(() => redirectToApplicationsList(), 10000);
                break;
            default:
                if (retryCount < 10)    
                    setTimeout(() => verifyPayment(), 5000);
                else
                    setTimeout(() => redirectToApplicationsList(), 10000);
                break;
        }
    }, [retryCount]);

    return (
        <main className="d-flex flex-row h-100 ">
            <Sidebar />
            <div className='w-100 overflow-auto'>
                <CardHeader text="Płatność zakończona" onlyText="true" />
                {loading ? (
                    <LoadingSpinner text={'Trwa weryfikacja płatności.'} />
                ) : (
                    <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                        <div className='d-flex flex-column'>
                            {(paymentStatus === 'COMPLETED') && (
                                <div className='w-100 d-flex flex-row align-items-center'>
                                        <div>
                                            <i className="fs-1 bi bi-check-circle-fill text-success" />
                                        </div>

                                        <div className='ms-1'>
                                            <strong className='fs-4'>Dziękujemy za dokonanie płatności</strong><br />
                                            <span>Płatność zostanie wkrótce potwierdzona przez operatora. Za chwilę przeniesiemy Cię do listy Twoich wniosków.</span>
                                        </div>
                                    </div>
                            )}
                            {(paymentStatus === 'CANCELED') && (
                                <div className='w-100 d-flex flex-row align-items-center'>
                                    <div>
                                        <i className="fs-1 bi bi-x-circle-fill text-danger" />
                                    </div>

                                    <div className='ms-1'>
                                        <strong className='fs-4'>Płatność anulowana</strong><br />
                                        <span>Płatność została odrzucona. Za chwilę przeniesiemy Cię na stronę, na której możesz ponowić płatność.</span>
                                    </div>
                                </div>
                            )}
                            {((paymentStatus !== 'CANCELED') && (paymentStatus !== 'COMPLETED') && (retryCount === 10)) && (
                                <div className='w-100 d-flex flex-row align-items-center'>
                                    <div>
                                        <i className="fs-1 bi bi-question-circle-fill text-warning" />
                                    </div>

                                    <div className='ms-1'>
                                        <strong className='fs-4'>Płatność nie została zweryfikowana</strong><br />
                                        <span>Nie udało się zweryfikować statusu płatności w wyznaczonym czasie. Za chwilę przeniesiemy Cię do listy Twoich wniosków.</span>
                                    </div>
                                </div>
                            )}
               
                            {(["NEW", "PENDING", "WAITING_FOR_CONFIRMATION"].includes(paymentStatus) && retryCount < 10) && (
                                <div className='w-100 d-flex flex-row align-items-center'>
                                    <div>
                                        <LoadingSpinner className="fs-1" text="" small={false} />
                                    </div>

                                    <div className='ms-1'>
                                        <strong className='fs-4'>{'Trwa weryfikacja płatności (próba ' + retryCount + " z 10)"}</strong><br />
                                        <span>Proszę czekać na weryfikację stanu płatności.</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </main>
    );
}

export default PaymentFinish;
