function LoadingSpinner({text = "Pobieranie danych", className = "", small = true}) {
    return (
        <div className={"d-flex flex-row " + className}>
            <div className={"spinner-border spinner-border" + (small ? "-sm" : "") + " align-self-center"} role="status">
                <span className="visually-hidden">...</span>
            </div>

            <div className="ms-1">
                {text}
            </div>
        </div>
    )
}

export default LoadingSpinner;