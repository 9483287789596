import React, { useEffect, useState } from 'react';
import StatusBadge from './StatusBadge';
import CardHeader from './CardHeader';
import LoadingSpinner from './LoadingSpinner';
import { getUsers, getChambers } from '../../services/Dictionary';
import { assignChamber, assignUser } from '../../services/Application';
import { useAuth } from '../../context/AuthContext';

const CaseDetails = ({ caseData, displayModeHandler, displayMode = "Client" }) => {
    const [statusColor, setStatusColor] = useState('blue');
    const [description, setDescription] = useState('Oczekuj na informację zwrotną.');
    const [header, setHeader] = useState('Brak informacji');
    const [users, setUsers] = useState();
    const [chambers, setChambers] = useState();
    const [operatorName, setOperatorName] = useState(caseData.operator);
    const [chamberName, setChamberName] = useState(caseData.chamber);
    const [chamberId, setChamberId] = useState(caseData.chamberId);
    const [showUserSelect, setShowUserSelect] = useState(false);
    const [showChamberSelect, setShowChamberSelect] = useState(false);
    const { checkPermission } = useAuth();

    const descriptions = {
        Client: [
            { id: 1, status: 'roboczy', header: '', description: '' },
            { id: 2, status: 'wysłany', header: 'Wysłany', description: 'Wniosek został wysłany. Oczekuj na informację zwrotną.' },
            { id: 3, status: 'wysłany', header: 'Wysłany', description: 'Wniosek został wysłany i przypisany do operatora. Oczekuj na informację zwrotną.' },
            { id: 4, status: 'popraw', header: 'Popraw', description: 'Wniosek został odesłany w celu jego uzupełnienia. Sprawdź komentarze operatora we wniosku, uzupełnij wniosek i wyślij go ponownie.' },
            { id: 5, status: 'weryfikacja opłaty', header: 'W akceptacji', description: 'Wniosek jest opłacony, został skierowany do weryfikacji przez operatora.' },
            { id: 6, status: 'opłać', header: 'Opłać', description: 'Wniosek anulowany, wymagane jest opłacenie wniosku.' },
            { id: 7, status: 'oczekiwanie na anulowanie', header: 'Do anulowania', description: 'Wniosek został przekazany do anulowania i oczekuje na rozpatrzenie przez operatora.' },
            { id: 8, status: 'anulowany', header: 'Anulowany', description: 'Wniosek został anulowany, aby uzyskać nowe świadectwo złóż kolejny wniosek.' },
            { id: 9, status: 'do opłaty i podpisu', header: 'Opłać i podpisz', description: 'Wymagane jest opłacenie i podpisanie wniosku.' },
            { id: 10, status: 'weryfikacja podpisu', header: 'W akceptacji', description: 'Wniosek jest opłacony i podpisany, został skierowany do weryfikacji przez operatora.' },
            { id: 11, status: 'do opłacenia', header: 'Opłać', description: 'Wymagane jest opłacenie wniosku.' },
            { id: 12, status: 'podpisz', header: 'Podpisz', description: 'Wniosek został opłacony. Wymagane jest podpisanie wniosku.' },
            { id: 13, status: 'w akceptacji', header: 'W akceptacji', description: 'Wniosek jest opłacony i podpisany, został skierowany do weryfikacji przez operatora.' },
            { id: 14, status: 'oczekuje na wydruk', header: 'Do wydruku', description: 'Świadectwo zostało wystawione i można je pobrać. Wersja papierowa oczekuje na wydruk.' },
            { id: 15, status: 'odbierz', header: 'Do odbioru', description: 'Świadectwo zostało wystawione i można je pobrać. Wydruk świadectwa oczekuje na odbiór.' },
            { id: 16, status: 'oczekuje na wysyłkę', header: 'Do wysyłki', description: 'Świadectwo zostało wystawione i można je pobrać. Wersja papierowa oczekuje na wysyłkę.' },
            { id: 17, status: 'wysłano', header: 'Wysłano', description: 'Świadectwo zostało wystawione i można je pobrać. Wersja papierowa została wysłana.' },
            { id: 18, status: 'wystawione', header: 'Wystawione', description: 'Świadectwo zostało wystawione i można je pobrać.' }
        ],
        Operator: [
            { id: 1, status: 'roboczy', header: '', description: '' },
            { id: 2, status: 'nowy', header: 'Przypisz operatora', description: 'Nowy wniosek, wymaga przypisania do operatora.' },
            { id: 3, status: 'weryfikacja', header: 'Do weryfikacji', description: 'Wniosek wymaga weryfikacji.' },
            { id: 4, status: 'do poprawy', header: 'Do poprawy', description: 'Wniosek został odesłany klientowi w celu jego uzupełnienia.' },
            { id: 5, status: 'weryfikacja opłaty', header: 'Weryfikacja opłaty', description: 'Wniosek oczekuje na weryfikację płatności.' },
            { id: 6, status: 'opłata za usługę', header: 'Opłata za usługę', description: 'Wniosek anulowany, został przekazany klientowi do opłaty.' },
            { id: 7, status: 'do anulowania', header: 'Do anulacji', description: 'Wniosek został zgłoszony do anulowania. Wskaż czy wymagana jest opłata.' },
            { id: 8, status: 'anulowany', header: 'Anulowany', description: 'Wniosek został anulowany.' },
            { id: 9, status: 'do opłaty i podpisu', header: 'Do opłaty i podpisu', description: 'Wymagane jest opłacenie i podpisanie wniosku przez klienta.' },
            { id: 10, status: 'weryfikacja podpisu', header: 'Zweryfikuj podpis', description: 'Klient dodał podpisany wniosek, podpis wymaga weryfikacji.' },
            { id: 11, status: 'do opłacenia', header: 'Do opłaty', description: 'Wymagane jest opłacenie wniosku przez klienta.' },
            { id: 12, status: 'do podpisu', header: 'Do podpisu', description: 'Wniosek został opłacony. Wymagane jest podpisanie wniosku przez klienta.' },
            { id: 13, status: 'do wydania', header: 'Do wydania', description: 'Wniosek jest opłacony i podpisany, można wystawić świadectwo.' },
            { id: 14, status: 'do wydruku', header: 'Do wydruku', description: 'Świadectwo zostało wystawione i można je wydrukować.' },
            { id: 15, status: 'do odbioru', header: 'Do odbioru', description: 'Świadectwo zostało wydrukowane i oczekuje na odbiór przez klienta.' },
            { id: 16, status: 'do wysyłki', header: 'Do wysyłki', description: 'Świadectwo zostało wydrukowane i oczekuje na wysyłkę do klienta.' },
            { id: 17, status: 'wysłano', header: 'Wysłano', description: 'Świadectwo zostało wydrukowane i wysłane do klienta.' },
            { id: 18, status: 'wystawione', header: 'Wystawione', description: 'Świadectwo zostało wydane, sprawa została zamknięta.' }
        ]
    };

    const colorMapping = {
        Client: [
            { id: 1, status: 'Roboczy', bgColor: 'light-green', textColor: 'black' },
            { id: 2, status: 'Wysłany', bgColor: 'light-grey', textColor: 'black' },
            { id: 3, status: 'Wysłany', bgColor: 'light-grey', textColor: 'black' },
            { id: 4, status: 'Popraw', bgColor: 'red', textColor: 'white' },
            { id: 5, status: 'Weryfikacja opłaty', bgColor: 'light-grey', textColor: 'black' },
            { id: 6, status: 'Opłać', bgColor: 'orange', textColor: 'black' },
            { id: 7, status: 'Oczekiwanie na anulowanie', bgColor: 'light-violet', textColor: 'black' },
            { id: 8, status: 'Anulowany', bgColor: 'violet', textColor: 'white' },
            { id: 9, status: 'Do opłaty i podpisu', bgColor: 'orange', textColor: 'black' },
            { id: 10, status: 'Weryfikacja podpisu', bgColor: 'light-grey', textColor: 'black' },
            { id: 11, status: 'Do opłacenia', bgColor: 'orange', textColor: 'black' },
            { id: 12, status: 'Podpisz', bgColor: 'orange', textColor: 'black' },
            { id: 13, status: 'W akceptacji', bgColor: 'light-grey', textColor: 'black' },
            { id: 14, status: 'Oczekuje na wydruk', bgColor: 'light-grey', textColor: 'black' },
            { id: 15, status: 'Odbierz', bgColor: 'green2', textColor: 'black' },
            { id: 16, status: 'Oczekuje na wysyłkę', bgColor: 'light-grey', textColor: 'black' },
            { id: 17, status: 'Wysłano', bgColor: 'green2', textColor: 'black' },
            { id: 18, status: 'Wystawione', bgColor: 'green2', textColor: 'black' }
        ],
        Operator: [
            { id: 1, status: 'Roboczy', bgColor: 'light-green', textColor: 'black' },
            { id: 2, status: 'Nowy', bgColor: 'green', textColor: 'black' },
            { id: 3, status: 'Weryfikacja', bgColor: 'blue', textColor: 'black' },
            { id: 4, status: 'Do poprawy', bgColor: 'light-grey', textColor: 'black' },
            { id: 5, status: 'Weryfikacja opłaty', bgColor: 'orange', textColor: 'black' },
            { id: 6, status: 'Opłata za usługę', bgColor: 'light-grey', textColor: 'black' },
            { id: 7, status: 'Do anulowania', bgColor: 'light-violet', textColor: 'black' },
            { id: 8, status: 'Anulowany', bgColor: 'violet', textColor: 'white' },
            { id: 9, status: 'Do opłaty i podpisu', bgColor: 'light-grey', textColor: 'black' },
            { id: 10, status: 'Weryfikacja podpisu', bgColor: 'orange', textColor: 'black' },
            { id: 11, status: 'Do opłacenia', bgColor: 'light-grey', textColor: 'black' },
            { id: 12, status: 'Do podpisu', bgColor: 'light-grey', textColor: 'black' },
            { id: 13, status: 'Do wydania', bgColor: 'yellow', textColor: 'black' },
            { id: 14, status: 'Do wydruku', bgColor: 'yellow', textColor: 'black' },
            { id: 15, status: 'Do odbioru', bgColor: 'light-grey', textColor: 'black' },
            { id: 16, status: 'Do wysyłki', bgColor: 'yellow', textColor: 'black' },
            { id: 17, status: 'Wysłano', bgColor: 'green2', textColor: 'black' },
            { id: 18, status: 'Wystawione', bgColor: 'green2', textColor: 'black' }
        ]
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year} r.`;
    };

    const handleUserChange = async (e) => {
        let userId = e.target.value;
        setOperatorName(users.find(u => u.Id === userId).Name);
        let response = await assignUser(caseData.id, userId);
        let response2 = await assignChamber(caseData.id, chamberId);
        if (response && response2) {
            setShowUserSelect(false);
        }
    };

    const handleChamberChange = async (e) => {
        let selectedChamberId = e.target.value;
        setShowChamberSelect(false);
        setChamberId(selectedChamberId);
        setChamberName(chambers.find(c => c.Id === selectedChamberId).Name);
        setShowUserSelect(true);
    };

    useEffect(() => {

        if (caseData !== null && caseData.status !== null) {
            let cMap = displayMode === 'Client' ? colorMapping.Client.find(d => d.id === caseData.statusId) : colorMapping.Operator.find(d => d.id === caseData.statusId);
            let desc = (displayMode === 'Client') ? descriptions.Client.find(d => d.id === caseData.statusId) : descriptions.Operator.find(d => d.id === caseData.statusId);
            setStatusColor(cMap ? cMap.bgColor : 'blue');
            setDescription(desc ? desc.description : 'Oczekuj na informację zwrotną.');
            setHeader(desc ? desc.header : 'Brak informacji');
        };

    }, [caseData]);

    useEffect(() => {
        const fetchData = async () => {
            let tempChambers = await getChambers();
            setChambers(tempChambers);
            let tempUsers = await getUsers(caseData.chamberId);
            setUsers(tempUsers);
            // setOperatorName(caseData.operator);
            // setChamberName(caseData.chamber);
            // setChamberId(caseData.chamberId);
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            let tempUsers = await getUsers(chamberId);
            setUsers(tempUsers);
        };

        fetchData();
    }, [chamberId]);

    if (!caseData) {
        return (
            <div>
                <CardHeader text="Szczegóły sprawy" onlyText="true" />
                <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                    <div className='d-flex flex-column w47'>
                        <LoadingSpinner text='Pobieranie danych sprawy' />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <CardHeader text="Szczegóły sprawy" onlyText="true" />
            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <div className='d-flex flex-row'>
                        <div className='w7'>
                            <strong>Nazwa</strong>
                        </div>
                        <div className='w15 ms-1'>
                            <span>{caseData.name}</span>
                        </div>
                        <div className='w7 ms-1'>
                            <strong>Nr wniosku</strong>
                        </div>
                        <div className='w15 ms-1'>
                            <span>{("000000" + caseData.number).slice(-7)}</span>
                        </div>
                    </div>

                    <div className='d-flex flex-row mt-1'>
                        <div className='w7'>
                            <strong>Status</strong>
                        </div>
                        <div className='w15 ms-1'>
                            <StatusBadge
                                text={
                                    displayMode === 'Client' ?
                                        colorMapping.Client.find(d => d.id === caseData.statusId).status :
                                        colorMapping.Operator.find(d => d.id === caseData.statusId).status
                                }
                                colorMode={displayMode}
                            />
                        </div>

                        <div className='w7 ms-1'>
                            <strong>Eksporter</strong>
                        </div>
                        <div className='w15 ms-1'>
                            <span>{caseData.exporterName}</span>
                        </div>
                    </div>

                    <div className='d-flex flex-row mt-1'>
                        <div className='w7'>
                            <strong>Data utworzenia</strong>
                        </div>
                        <div className='w15 ms-1'>
                            <span>{caseData.createDateAndTime}</span>
                        </div>

                        <div className='w7 ms-1'>
                            <strong>NIP</strong>
                        </div>
                        <div className='w15 ms-1'>
                            <span>{caseData.exporterNip}</span>
                        </div>
                    </div>

                    <div className='d-flex flex-row mt-1'>
                        <div className='w7'>
                            <strong>Wnioskujący</strong>
                        </div>
                        <div className='w15 ms-1'>
                            <span>{caseData.applicantName}</span>
                        </div>

                        <div className='w7 ms-1'>
                            <strong>Telefon</strong>
                        </div>
                        <div className='w15 ms-1'>
                            <span>{caseData.applicantPhone}</span>
                        </div>
                    </div>

                    <div className={`status-panel ${statusColor} p-1 mt-2`}>
                        <div className='fs-3 mb-1'>{capitalizeFirstLetter(header)}</div>
                        <div>{description}</div>
                    </div>

                    {checkPermission("Zmiana przypisania sprawy") ? (
                        <div className='d-flex flex-row mt-2'>
                        <div className='w7'>
                            <strong>Sprawę obsługuje</strong>
                        </div>
                        {/* <span className='ms-1'>{caseData.chamber}</span> */}
                        {showChamberSelect ? (
                            <div className='w15 ms-1'>
                                <select className='form-select w-100' onChange={handleChamberChange}>
                                    <option value=''>Wybierz ...</option>
                                    {chambers && chambers.map((chamber) => {
                                        return <option key={chamber.Id} value={chamber.Id}>{chamber.Name} ({chamber.City})</option>
                                    })}
                                </select>
                            </div>
                        ) : (
                            <div className='w15 ms-1'>
                                <a href='#' onClick={() => setShowChamberSelect(!showChamberSelect)}>{chamberName}</a>
                            </div>
                        )}
                    </div>
                    ) : (
                        <div className='d-flex flex-row mt-1'>
                            <div className='w7'>
                                <strong>Sprawę obsługuje</strong>
                            </div>
                            <div className='w15 ms-1'>
                                <span>{chamberName}</span>
                            </div>
                        </div>
                    )}

                    {checkPermission("Zmiana przypisania sprawy") && (
                        <div className='d-flex flex-row mt-1'>
                            <div className='w7'>
                                <strong>Operator</strong>
                            </div>
                            {showUserSelect ? (
                                <div className='w15 ms-1'>
                                    <select className='form-select w-100' onChange={handleUserChange}>
                                        <option value=''>Wybierz ...</option>
                                        {users && users.map((user) => {
                                            return <option key={user.Id} value={user.Id}>{user.Name}</option>
                                        })}
                                    </select>
                                </div>
                            ) : (
                                <div className='w15 ms-1'>
                                    <a href='#' onClick={() => setShowUserSelect(!showUserSelect)}>{operatorName}</a>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CaseDetails;
