import React, {useState} from 'react';
import CardHeader from './CardHeader';
import { ApiHost } from '../../utils/ApiConfig';
import { useAuth } from '../../context/AuthContext';
import LoadingSpinner from './LoadingSpinner';

const CertificateDetails = ({ applicationData, displayMode = "Client", onShippingInfoUpdate }) => {
    const pickupStatuses = [14, 15, 18];
    const certificateReadyToDownloadStatuses = [14, 15, 16, 17, 18];
    const courierStatuses = [13, 14, 16, 17, 18];
    const trackingStatuses = [16, 17, 18];
    const trackingInputStatuses = [16, 17, 18];
    const { accessToken } = useAuth();

    const [trackingInputSwitch, setTrackingInputSwitch] = useState(false);
    const [shippingInfo, setShippingInfo] = useState(applicationData.shippingInfo);
    const [loading, setLoading] = useState(false);
    const [pritnting, setPrinting] = useState(false);

    const ShippingInfoEnter = (e) => {
        setShippingInfo(e.target.value);
    };

    const formatDate = (dateString) => {
        try {
            const [year, month, day] = dateString.split('-');
            if (!year || !month || !day) throw new Error("Invalid date format: " + dateString);
            return `${day}.${month}.${year} r.`;
        } catch (error) {
            console.error("Error formatting date:", error);
            return "-";
        }
    };

    const extractTrackingNumber = (trackingInfo) => {
        try {
            if (!trackingInfo.includes("furgonetka.pl"))
                return trackingInfo;
            const trackingNumber = trackingInfo.split('/')[4]; 
            return trackingNumber;
        } catch (error) {
            console.error("Error extracting tracking number:", error);
            return "-";
        }
    }

    const downloadFile = async (print = false) => {
        try {
            if (print)
                setPrinting(true);
            else
                setLoading(true);
            const applicationId = applicationData.id;
            if (!applicationId)
                throw "Brak identyfikatora wniosku";
               
            const response = await fetch(ApiHost + '/certificate/' + (print ? "print" : "original") + '/' + applicationId, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Błąd podczas pobierania pliku');
            }
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = `swiadectwo_${applicationData.id}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();

        } catch (error) {
            console.error('Błąd podczas pobierania pliku:', error);
            throw error;
        } finally {
            if (print)
                setPrinting(false);
            else
                setLoading(false);
        }
    };

    return (
        <div>
            <CardHeader text="Szczegóły świadectwa" onlyText="true" />
            <div className='card container-fluid shadow ms-4 px-4 py-3 w55'>
                <div className='d-flex flex-column w47'>
                    <div className='d-flex flex-row'>
                        <div>
                            <i className='bi bi-file-earmark-fill text-black-50 fs-1'></i>
                        </div>
                        <div className='ms-1'>
                            <strong>Świadectwo nr {applicationData.number}</strong><br />
                            {certificateReadyToDownloadStatuses.includes(applicationData.status) && (
                                <span className='text-black-50'>Świadectwo można zweryfikować na stronie internetowej <a href="#">swiadectwa.kig.pl</a></span>
                            )}
                        </div>
                        {certificateReadyToDownloadStatuses.includes(applicationData.status) && (
                            <div className='ms-auto'>
                                <div className='d-flex flex-column'>
                                    {loading ? (
                                        <div className='btn btn-primary ms-auto w11 h2'>
                                            <LoadingSpinner text='Pobieranie' />
                                        </div>
                                    ) : (
                                        <button className='btn btn-primary ms-auto w11 h2' onClick={() => downloadFile()}>Pobierz</button>
                                    )}

                                    {pritnting ? (
                                        <div className='btn btn-outline-primary mt-1 ms-auto w11 h2'>
                                            <LoadingSpinner text='Drukowanie' />
                                        </div>
                                    ) : (
                                        <button className='btn btn-outline-primary mt-1 w11 h2' onClick={() => downloadFile(true)}>Wydruk</button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {applicationData.paymentShipping === "pickup" && pickupStatuses.includes(applicationData.status) && (
                        <div className='border-top pt-1 mt-1 d-flex flex-column'>
                            <div className='w-100 d-flex flex-row align-items-center'>
                                <div>
                                    <div className='rounded-circle bg-warning d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px' }}>
                                        <i className='bi bi-truck text-white'></i>
                                    </div>
                                </div>

                                <div className='ms-1'>
                                    <strong>Forma dostawy - odbiór osobisty</strong><br />
                                </div>
                            </div>

                            <div className='mt-1'>
                                Świadectwo można odebrać w:<br />
                                <div className='ms-1 my-1'>
                                    KIG - Biuro BLCA - Zespół Świadectw Pochodzenia<br />
                                    ul. Trębacka 4, pok. 04 parter<br />
                                    00-074 Warszawa<br />
                                    tel. 22 630 96 17 | 22 630 97 03 | 22 630 96 79
                                </div>
                                Zapraszamy po odbiór od poniedziałku do piątku w godzinach 9:00 - 15:00.
                            </div>
                        </div>
                    )}

                    {applicationData.paymentShipping === "courier" && courierStatuses.includes(applicationData.status) && (
                        <div className='border-top pt-1 mt-1 d-flex flex-column'>
                            <div className='w-100 d-flex flex-row align-items-center'>
                                <div>
                                    <div className={'rounded-circle ' + (applicationData.status === 16 ? 'bg-warning' : 'bg-success') + ' d-flex justify-content-center align-items-center'} style={{ width: '30px', height: '30px' }}>
                                        <i className='bi bi-truck text-white'></i>
                                    </div>
                                </div>

                                <div className='ms-1'>
                                    <strong>Forma dostawy - wysyłka</strong><br />
                                    {applicationData.status === 16 && (
                                        <span className='text-black-50'>Oczekuje na wysyłkę</span>
                                    )}
                                    {trackingStatuses.includes(applicationData.status) && (
                                        <span className='text-black-50'>Nadano: {formatDate(applicationData.shippingDateSent)}</span>
                                    )}
                                </div>
                            </div>

                            <div className='mt-1'>
                                Świadectwo zostanie dostarczone na adres:<br />
                                <div className='ms-1 mt-1'>
                                    {applicationData.paymentShippingAddress.name}<br />
                                    {applicationData.paymentShippingAddress.street} {applicationData.paymentShippingAddress.building} {applicationData.paymentShippingAddress.appartment ? "/ " + applicationData.paymentShippingAddress.appartment : ""}<br />
                                    {applicationData.paymentShippingAddress.postCode} {applicationData.paymentShippingAddress.city} ({applicationData.paymentShippingAddress.country})<br />
                                    tel. {applicationData.paymentShippingAddress.phoneNumber}
                                </div>
                            </div>

                            {trackingStatuses.includes(applicationData.status) && (!trackingInputSwitch) && (
                                <div className='d-flex flex-row mt-1'>
                                    <div className='w7'>
                                        <strong>Numer przesyłki</strong>
                                    </div>
                                        <div className='w15 ms-1'>
                                        {shippingInfo.startsWith("https://") ? (
                                            <span><a href={shippingInfo} target='_blank'>{extractTrackingNumber(shippingInfo)}</a></span>
                                        ) : (
                                            <span>{shippingInfo}</span>
                                        )}
                                        </div>
                                        {(["Operator", "Shipping"].includes(displayMode)) && (trackingInputStatuses.includes(applicationData.status)) && (
                                            <div className='ms-1'>
                                                <button className='btn btn-outline-primary w7 h2' onClick={() => setTrackingInputSwitch(!trackingInputSwitch)}>Zmień</button>
                                            </div>
                                        )}
                                </div>
                            )}
                            {trackingInputStatuses.includes(applicationData.status) && (trackingInputSwitch) && (
                                <div className='d-flex flex-row mt-1'>
                                    <div className='w7'>
                                        <strong>Śledzenie (link)</strong>
                                    </div>
                                    <div className='w15 ms-1'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={shippingInfo}
                                            onChange={ShippingInfoEnter}
                                            placeholder='Wprowadź dane do śledzenia przesyłki'
                                        />
                                    </div>
                                    <div className='ms-1'>
                                        <button
                                            className='btn btn-outline-primary w7 h2'
                                            onClick={() => {onShippingInfoUpdate(shippingInfo); setTrackingInputSwitch(!trackingInputSwitch)}}
                                        >
                                            Zapisz
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CertificateDetails;